<template>
  <header class="app-header">
    <div class="header">
      <h1 class="logo"><RouterLink to="/">麦迪克</RouterLink></h1>
      <!--  :class="{ fade: curIndex === i }" -->
      <div class="navs">
        <div class="navList">
          <div
            v-for="(item, i) in titleLink"
            :class="curIndex === i ? 'active' : ''"
            @mouseleave="leave"
            @mouseenter="enter(i)"
            @click="Jump(item.link, i + 1)"
            :key="i"
            class="onediv"
          >
            <p
              :class="['title', { navStyle: index == i + 1 }]"
              v-html="item.text"
              class="nav"
            ></p>
            <!-- v-if="current == i" -->
            <div class="slide" @mouseenter="enter(i)" v-if="current == i">
              <p
                @click="sendId(item.link, v.id)"
                class="tab-choose"
                v-for="(v, index) in item.children"
                :key="index"
              >
                {{ v.label }}
              </p>
            </div>
          </div>
          <!-- <div :class="{'navStyle':index == 1}" @click="Jump(1)">首&nbsp;&nbsp;页</div>
                <div :class="{'navStyle':index == 2}" @click="Jump(2)">关于麦迪克</div>
                <div :class="{'navStyle':index == 3}" @click="Jump(3)">商务合作</div>
                <div :class="{'navStyle':index == 4}" @click="Jump(4)" >招贤纳士</div>
                <div :class="{'navStyle':index == 5}" @click="Jump(5)">联系我们</div> -->
        </div>
        <!-- <div class="gather" v-if="pageYOffset > 0" ></div> -->
      </div>
    </div>
  </header>
</template>

<script>

export default {
  data () {
    return {
      curIndex: 0,
      index: 1,
      current: -1,
      pageYOffset: 0,
      jump: '',

      titleLink: [
        // 首页
        {
          link: '/',
          children: [
            {

              id: 'homes'
            }

          ],
          text: '首&nbsp;&nbsp;页'
        },
        // 关于麦迪克
        {
          link: '/instroduce',
          children: [
            {
              label: '公司简介',
              id: 'comProfile'
            },
            {
              label: '业务范围',
              id: 'scopeofService'
            },

            {
              label: '公司文化',
              id: 'corCulture'
            },
            {
              label: '团队风采',
              id: 'teamSpirit'
            }
          ],
          text: '关于麦迪克'
        },
        // 业务服务
        {
          link: '/service',
          children: [
            {
              label: '过往业绩',
              id: 'performance'
            },
            {
              label: '服务模式',
              id: 'processmode'
            },
            {
              label: '行业案例',
              id: 'businesscase'
            },
            {
              label: '公司荣誉',
              id: 'businesshonor'
            }
          ],
          text: '业务服务'
        },
        // 招聘信息
        {
          link: '/invite',
          children: [
            {
              label: '招聘信息',
              id: 'recruitmentInformation'
            },
            {
              label: '公司福利',
              id: 'beneJump'
            }

          ],
          text: '招贤纳士'
        },
        // 联系我们
        {
          link: '/contact',
          children: [

          ],
          text: '联系我们'
        }
      ]
    }
  },

  // 实现锚点链接开始
  created () {
    this.listenerFunction() // 开启监听函数
    this.index = sessionStorage.getItem('tabIndex') || 1
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.listenerFunction) // 组件销毁之前关闭滚动事件
  },
  mounted () {
    var id = sessionStorage.getItem('i')
    console.log('当前索引', id)
    this.curIndex = id
  },
  methods: {
    enter (i) {
      this.current = i // 鼠标进入指定的一级导航栏
    },
    // 点击2级导航栏触发的事件
    sendId (link, id) {
      console.log('2级导航栏对应的path和id', link, id)
      localStorage.setItem('toId', id)
      console.log('把id存到本地', localStorage)
      this.$emit('jumpId', id)
      console.log('this.$emit', this.$emit('jumpId', id))
    },
    leave () {
      this.current = -1
      // 鼠标离开后让指上的索引为-1
    },
    Jump (link, key) {
      console.log('新curIndex', this.curIndex)
      console.log('link,key', link, key)
      this.index = key

      this.$router.push(link)
      this.curIndex = key - 1
      // this.jump = link

      sessionStorage.setItem('tabIndex', key) // 存储key的值
      console.log('sessionStorage', sessionStorage.setItem('tabIndex', key))
    },
    listenerFunction (e) {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    handleScroll () {
      this.pageYOffset = window.pageYOffset
      // console.log(' this.pageYOffset', this.pageYOffset)
      // console.log(window.pageYOffset)
      // console.log(window.pageYOffset>=1)
    }
    // 实现锚点链接结束
  }
}
</script>

<style scoped lang='less'>
.app-header {
  width: 80rem;
  text-align: center;
  position: fixed;
  z-index: 9999;

  // background: #fff;
  //版心
  display: flex;
  .header {
    width: 80rem;
    height: 2.5rem;
    background-color: #181d2e;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 9999;
  }
  // logo区域
  .logo {
    width: 4.458rem;
    height: 1rem;
    line-height: 60px;
    margin-left: 15rem;
    a {
      display: block;
      height: 60px;
      line-height: 60px;

      width: 100%;
      text-indent: -9999px;
      background: url("../.../../../../assets/导航栏/logo.png") no-repeat 0 0 /
        contain;
    }
  }
  // 导航区域
  .navs {
    width: 29.958rem;
    height: 2.5rem;
    // background-color: red;
    display: flex;
    // justify-content: space-around;
    margin-left: 15.625rem;
    // .layer {
    //   width: 133px;
    //   height: 240px;

    //   overflow: hidden;
    //   opacity: 0; //透明度
    //   box-shadow: 0 0 5px #ccc;
    //   transition: all 0.2s 0.1s;

    //   ul {
    //     display: flex;
    //     flex-wrap: wrap;

    //     align-items: center;
    //     position: relative;
    //     z-index: 9999;

    //     li {
    //       width: 133px;
    //       text-align: center;
    //       &:hover {
    //         p {
    //           color: @xtxColor;
    //         }
    //       }
    //     }
    //   }
    // }
    // .layers {
    //   width: 133px;
    //   height: 120px;

    //   overflow: hidden;
    //   opacity: 0; //透明度
    //   box-shadow: 0 0 5px #ccc;
    //   transition: all 0.2s 0.1s;
    //   // position: relative;
    //   // z-index: 9999;
    //   ul {
    //     display: flex;
    //     flex-wrap: wrap;

    //     align-items: center;

    //     li {
    //       width: 133px;
    //       text-align: center;
    //       &:hover {
    //         p {
    //           color: @xtxColor;
    //         }
    //       }
    //     }
    //   }
    // }
    //默认首页有下划线
    // .home {
    //   border-bottom: 1px solid @xtxColor;
    //   // 初始样式 不显示

    //   // hover之后显示出来
    //   // &:hover {
    //   //   > a {
    //   //     color: @xtxColor;
    //   //     border-bottom: 1px solid @xtxColor;
    //   //   }
    //   //   > .layer {
    //   //     height: 120px;
    //   //     opacity: 1;
    //   //   }
    //   // }
    // }

    // li {
    //   width: 133px;
    //   height: 60px;
    //   // text-align: center;
    //   a {
    //     color: white;
    //     width: 133px;
    //     font-size: 16px;
    //     line-height: 60px;
    //     height: 60px;
    //     display: inline-block;
    //   }
    //   &:hover {
    //     > a {
    //       color: @xtxColor;
    //       border-bottom: 1px solid @xtxColor;
    //     }
    //     > .layer {
    //       height: 240px;
    //       opacity: 1;
    //     }
    //     > .layers {
    //       height: 120px;
    //       opacity: 1;
    //     }
    //   }
    // }
    .navList {
      display: flex;
      justify-content: space-around;
      .onediv {
        // padding-bottom: 20px;
        /* width: 140px; */
        width: 5.542rem;
        // height: 60px;
        color: #ffffff;
        font-size: 0.667rem;
        letter-spacing: 0.083rem;

        line-height: 2.5rem !important;
        text-align: center;
        cursor: pointer;
        text-shadow: 4px 4px 4px rgb(68, 67, 67);
        p {
          // height: 1.75rem;
          // height: 1.6rem;
          // line-height: 1.75rem;

          .navStyle {
            border-bottom: 2px solid rgb(253, 253, 253);
            // box-shadow: 0px 1px 0px 0px rgb(82, 80, 80);
            box-shadow: 0 0 5px #ccc;
          }
        }
      }
      .active {
        position: relative;
        border-bottom: 1px solid @xtxColor;
        background-color: #374570;
      }
      .slide {
        width: 5.542rem;
        // height: 10rem;
        // height: 5rem;

        overflow: hidden;
        // opacity: 0; //透明度
        // box-shadow: 0 0 5px #ccc;
        transition: all 0.2s 0.1s;
        // background-color: #374570;
        .tab-choose {
          transition: all 0.5s;
          font-size: 0.583rem;
          // height: 1.6rem;
          line-height: 1.6rem;
          background-color: #181d2e;
        }
        .tab-choose:hover {
          background-color: #374570;
        }
      }
      .nav {
        font-weight: 700;
      }
    }
  }
}
</style>
