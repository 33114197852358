<template>
  <div class="footer">
    <div class="foot-top">
      <div class="slogn"></div>
      <div class="text">
<!--        <div class="tell">010-57037792</div>-->
        <div class="website">网 址：www.mdkbj.com</div>
        <div class="email">邮 箱：maidike@163.com</div>
        <div class="address">地 址：北京市朝阳区建外SOHO东区B座2902</div>
      </div>
    </div>
    <div class="foot-foot">
      <span>
        Copyright © 北京麦迪克未来科技有限公司 2014-2024. All Right Reserved.
        京ICP备1000000号-1 京公网安备1101120200</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }

}
</script>

<style lang="less" scoped>
.footer {
  .foot-top {
    width: 80rem;
    height: 11.667rem;
    background: url("../../../assets/底部导航栏/7.png") no-repeat 0 0 / contain;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .slogn {
      width: 23.375rem;
      height: 4.583rem;
      background: url("../../../assets/底部导航栏/4.png") no-repeat 0 0 /
        contain;
      margin-left: 11rem;
    }
    .text {
      margin-right: 11rem;
      .tell {
        font-family: MicrosoftYaHei;
        font-size: 0.833rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.625rem;
        letter-spacing: 0.042rem;
        color: #ffffff;
      }
      .website {
        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.625rem;
        letter-spacing: 0.042rem;
        color: #8c8c8c;
      }
      .email {
        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.625rem;
        letter-spacing: 0.042rem;
        color: #8c8c8c;
      }
      .address {
        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.625rem;
        letter-spacing: 0.042rem;
        color: #8c8c8c;
      }
    }
  }
  .foot-foot {
    width: 80rem;
    height: 1.417rem;

    background-color: #31374e;
    // background: url("../../../assets/底部导航栏/6.png") no-repeat 0 0 / contain;
    text-align: center;
    span {
      font-family: MicrosoftYaHei;
      font-size: 0.5rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.625rem;
      letter-spacing: 0.083rem;
      color: #7d89b4;
    }
  }
}
</style>
