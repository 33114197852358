import axios from 'axios'
import Vue from 'vue'
import qs from 'qs'
import md5 from 'js-md5'

// 创建vue实例
const vue = new Vue()

// 接口路径 线上
const baseURL = 'https://api.mdkbj.com'

// 接口路径 开发
// const baseURL = 'http://devapi.mdkbj.com/'
/** **** 创建axios实例 ******/
const service = axios.create({
  baseURL: baseURL
})
/** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
  config => {
    const timestamp = (new Date()).getTime().toString()
    const timedata = timestamp.substring(0, timestamp.length - 3)
    const dateline = parseInt(timedata)
    const sign = md5('dateline=' + parseInt(timedata) + '&secretKey=7g2cf84185d032de45f95q2198a57')
    const data = qs.parse(config.data)
    config.data = qs.stringify({
      dateline: dateline,
      sign: sign,
      ...data
    })
    return config
  },
  error => {
    // 请求错误处理
    console.log(error)
  }
)

/** **** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  res => {
    // 成功请求到数据
    if (res.status === 200) {
      if (res.data.code === 500) {
        // 报错
        vue.$message.closeAll()
        vue.$message({
          type: 'error',
          message: res.data.msg
        })
        return Promise.reject(res.data.msg)
      } else if (res.data.code === 10001) {
        // t缺少token
        vue.$message.closeAll()
        vue.$message({
          type: 'error',
          message: res.data.msg
        })
        return Promise.reject(res.data.msg)
      } else if (res.data.code === 10002) {
        vue.$message.closeAll()
        vue.$message({
          type: 'error',
          message: res.data.msg
        })
        // Vue.prototype.router.push({
        //   path: "/login"
        // });
      } else if (res.data.code === 400) {
        // 缺少参数
        // console.log(res.data.msg);
        vue.$message.closeAll()
        vue.$message({
          type: 'error',
          message: res.data.msg
        })
        return Promise.reject(res.data.msg)
      } else if (res.data.code === 200 || res.data.code === 403) {
        // 正常
        return res
      }
    } else {
      vue.$message({
        type: 'error',
        message: '接口错误'
      })
      return Promise.reject(res)
    }
  },
  error => {
    // 响应错误处理
    return Promise.reject(error)
  }
)

export default service
