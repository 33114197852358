<template>
  <!-- 联系我们页面 -->
  <div>
    <!-- <Header @jumpId="getjump" /> -->
    <div class="contacthead">
      <div class="contact">
        <div class="firstcircle" @mouseleave="leave" @mouseenter="change">
          <img src="../../assets/联系我们/1.png" alt="" />
        </div>
        <div class="firstcircle2" @mouseleave="leave2" @mouseenter="change2">
          <img src="../../assets/联系我们/1.png" alt="" />
        </div>
        <div class="firstcircle3" @mouseleave="leave3" @mouseenter="change3">
          <img src="../../assets/联系我们/1.png" alt="" />
        </div>
        <div class="firstcircle4" @mouseleave="leave4" @mouseenter="change4">
          <img src="../../assets/联系我们/1.png" alt="" />
        </div>
        <div class="rightTop">
          <div class="rightToptitle">公司地址</div>
          <div class="rightToptitlefoot">Company address</div>
        </div>
        <div class="rightContent1" ref="contactfirstcircle">
          <div class="rightContent1top">
            <span class="rightContent1topleft"> 北京总部</span>

            <span class="rightContent1topright"
              >北京麦迪克未来科技有限公司</span
            >
          </div>
          <div class="rightContent1content">
            <div class="rightContent1contentone">
              <div class="img">
                <img src="../../assets/联系我们/2.png" alt="" />
              </div>
              <div class="rightContent1contenttop">
                位 置:北京市朝阳区建外soho东区b座29层
              </div>
            </div>

            <div class="rightContent1contentonetwo">
              <div class="img">
                <img
                  class="email"
                  src="../../assets/联系我们/邮箱.png"
                  alt=""
                />
              </div>
              <div class="rightContent1contentfoot">邮 箱：liukaili@maidike.top</div>
            </div>
          </div>
        </div>
        <div class="rightContent2" ref="contactfirstcircle3">
          <div class="rightContent2top">
            <span class="rightContent2topleft"> 武汉分部</span>

            <span class="rightContent2topright">武汉麦迪克思科技有限公司</span>
          </div>
          <div class="rightContent2content">
            <div class="rightContent2contentone">
              <div class="img">
                <img src="../../assets/联系我们/2.png" alt="" />
              </div>
              <div class="rightContent2contenttop">
                位 置:武汉市江夏区金融港光谷智慧园1号楼604
              </div>
            </div>
            <div class="rightContent2contenttwo">
              <div class="img">
                <img
                  class="email2"
                  src="../../assets/联系我们/邮箱.png"
                  alt=""
                />
              </div>
              <div class="rightContent2contentfoot">
                邮 箱：chenyuan@maidike.top
              </div>
            </div>
          </div>
        </div>
        <div class="rightContent3" ref="contactfirstcircle2">
          <div class="rightContent3top">
            <span class="rightContent3topleft"> 合肥分部</span>

            <span class="rightContent3topright"
              >合肥麦东东信息科技有限公司</span
            >
          </div>
          <div class="rightContent3content">
            <div class="rightContent3contentone">
              <div class="img">
                <img src="../../assets/联系我们/2.png" alt="" />
              </div>
              <div class="rightContent3contenttop">
                位 置:安徽省合肥市蜀山区东流路西999号 <br />新际商务中心C幢1105
              </div>
            </div>

            <div class="rightContent3contenttwo">
              <div class="img">
                <img
                  class="email3"
                  src="../../assets/联系我们/邮箱.png"
                  alt=""
                />
              </div>

              <div class="rightContent3contentfoot">
                邮 箱：zhangjiaojiao@maidike.top
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../Layout/components/footer.vue'
// import Header from '@/views/Layout/components/header.vue'
export default {
  components: {
    // Header,
    Footer
  },
  data () {
    return {

    }
  },
  created () {
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
  },
  methods: {
    // 跳路由的时候用的
    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    },
    getlocal () {
      // 找到锚点id
      const selectId = localStorage.getItem('toId')
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    },
    change () {
      // console.log('来看看', this.$refs.contactfirstcircle)
      this.$refs.contactfirstcircle.style.backgroundColor = '#374570'
    },
    leave () {
      this.$refs.contactfirstcircle.style.backgroundColor = '#0f121e'
    },
    change2 () {
      // console.log('来看看', this.$refs.contactfirstcircle2)
      this.$refs.contactfirstcircle2.style.backgroundColor = '#374570'
    },
    leave2 () {
      this.$refs.contactfirstcircle2.style.backgroundColor = '#0f121e'
    },
    change3 () {
      // console.log('来看看', this.$refs.contactfirstcircle3)
      this.$refs.contactfirstcircle3.style.backgroundColor = '#374570'
    },
    leave3 () {
      this.$refs.contactfirstcircle3.style.backgroundColor = '#0f121e'
    },
    leave4 () {
      this.$refs.contactfirstcircle4.style.backgroundColor = '#0f121e'
    },
    change4 () {
      // console.log('来看看', this.$refs.contactfirstcircle4)
      this.$refs.contactfirstcircle4.style.backgroundColor = '#374570'
    }
  }
}
</script>

<style scoped lang="less">
.contacthead {
  width: 80rem;
  height: 42.5rem;
  overflow: hidden;
  background-color: #fff;

  .contact {
    width: 80rem;
    height: 42.5rem;
    background: url("../../assets/联系我们/联系我们-底.png") no-repeat 0 0 /
      contain;
    overflow: hidden;
    background-position: 0, 5rem, 0, 0;

    .firstcircle {
      width: 2.333rem;
      height: 2.333rem;
      border-radius: 50%;
      // background-color: red;
      margin-right: 4.167rem;
      margin-top: 16.25rem;
      margin-left: 31.5rem;
      background-color: #222d5e;
      overflow: hidden;
      opacity: 0.9;

      img {
        width: 1.083rem;
        height: 1.083rem;
        // text-align: center;
        margin-left: 0.625rem;
        margin-top: 0.625rem;
      }
      // .firstcircle:hover {
      //   background-color: red;
      //   box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
      //   transform: translateY(-5px);
      // }
    }
    .firstcircle2 {
      width: 1.417rem;
      height: 1.417rem;
      border-radius: 50%;
      // background-color: red;
      margin-right: 4.167rem;
      margin-top: 5.083rem;
      margin-left: 33.542rem;
      background-color: #222d5e;
      overflow: hidden;
      opacity: 0.9;
      img {
        width: 0.667rem;
        height: 0.667rem;
        // text-align: center;
        margin-left: 0.417rem;
        margin-top: 0.417rem;
      }
    }
    .firstcircle3 {
      width: 1.417rem;
      height: 1.417rem;
      border-radius: 50%;
      // background-color: red;
      margin-right: 4.167rem;
      margin-top: 0.25rem;
      margin-left: 30.917rem;
      background-color: #222d5e;
      overflow: hidden;
      opacity: 0.9;
      img {
        width: 0.667rem;
        height: 0.667rem;
        // text-align: center;
        margin-left: 0.417rem;
        margin-top: 0.417rem;
      }
    }
    .firstcircle4 {
      width: 1.417rem;
      height: 1.417rem;
      border-radius: 50%;
      // background-color: red;
      // margin-right: 4.167rem;
      margin-top: -2rem;
      margin-left: 35.5rem;
      background-color: #222d5e;
      overflow: hidden;
      opacity: 0.9;
      img {
        width: 0.667rem;
        height: 0.667rem;
        // text-align: center;
        margin-left: 0.417rem;
        margin-top: 0.417rem;
      }
    }
    .rightTop {
      // width: 8.083rem;
      height: 2.708rem;
      // background-color: red;
      margin-top: -18.958rem;
      margin-left: 46.083rem;
      border-left: 0.333rem solid white;
      .rightToptitle {
        // width: 132px;
        // height: 30px;
        font-family: SourceHanSansSC-Medium;
        font-size: 1.333rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
        margin-left: 0.833rem;
      }
      .rightToptitlefoot {
        // width: 166px;
        // height: 17px;
        font-family: SourceHanSansSC-Light;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.125rem;
        color: #9fafdf;
        margin-left: 0.833rem;
      }
    }
    .rightContent1 {
      width: 20.833rem;
      height: 7.042rem;
      background-color: #0f121e;
      margin-left: 46.083rem;
      margin-top: 1.167rem;
      .rightContent1top {
        width: 20.833rem;
        height: 2.5rem;
        margin-bottom: 0.125rem;
        border-bottom: 0.042rem solid #07090e;

        // background-color: red;
        .rightContent1topleft {
          line-height: 2.5rem;
          width: 4rem;
          height: 1rem;
          font-family: SourceHanSansSC-Medium;
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 53px;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 1.167rem;
          border-right: 0.125rem solid #92b0e8;
          padding-right: 0.542rem;
        }
        .rightContent1topright {
          // width: 283px;
          // height: 20px;
          font-family: MicrosoftYaHei;
          font-size: 0.833rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 16px;
          letter-spacing: 0.083rem;
          color: #d6e9ff;
          margin-left: 0.5rem;
        }
      }
      .rightContent1content {
        width: 20.833rem;
        height: 4.5rem;
        // background-color: red;
        overflow: hidden;
        position: relative;
        .rightContent1contentone {
          height: 0.75rem;
          img {
            position: absolute;
            width: 0.792rem;
            height: 0.792rem;
            left: 1.167rem;
            top: 1.042rem;
          }
          .rightContent1contenttop {
            // width: 303px;
            // height: 18px;
            font-family: MicrosoftYaHeiLight;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
            margin-top: 0.917rem;
          }
        }
        .rightContent1contentonetwo {
          height: 0.75rem;
          margin-top: 0.792rem;
          .rightContent1contentfoot {
            height: 0.708rem;

            font-family: MicrosoftYaHei;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 1.625rem;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
          }
          img {
            width: 0.708rem;
            height: 0.5rem;
            position: absolute;
            left: 1.167rem;
            top: 2.667rem;
          }
        }
      }
    }
    .rightContent2 {
      width: 20.833rem;
      height: 7.042rem;
      background-color: #0f131f;
      margin-left: 46.083rem;
      margin-top: 0.417rem;
      .rightContent2top {
        width: 20.833rem;
        height: 2.5rem;
        margin-bottom: 0.125rem;
        border-bottom: 0.042rem solid #07090e;
        .rightContent2topleft {
          line-height: 2.5rem;
          width: 4rem;
          height: 1rem;
          font-family: SourceHanSansSC-Medium;
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 53px;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 1.167rem;
          border-right: 0.125rem solid #92b0e8;
          padding-right: 0.542rem;
        }
        .rightContent2topright {
          font-family: MicrosoftYaHei;
          font-size: 0.833rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 16px;
          letter-spacing: 0.083rem;
          color: #d6e9ff;
          margin-left: 0.5rem;
        }
      }
      .rightContent2content {
        width: 20.833rem;
        height: 4.5rem;
        // background-color: red;
        overflow: hidden;
        position: relative;
        .rightContent2contentone {
          height: 0.75rem;
          .rightContent2contenttop {
            font-family: MicrosoftYaHeiLight;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
            margin-top: 0.917rem;
          }
          img {
            width: 0.792rem;
            height: 0.792rem;
            position: absolute;
            left: 1.167rem;
            top: 1.042rem;
          }
        }
        .rightContent2contenttwo {
          height: 0.75rem;
          margin-top: 0.792rem;

          .rightContent2contentfoot {
            height: 0.708rem;

            font-family: MicrosoftYaHei;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 1.625rem;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
          }
          img {
            width: 0.708rem;
            height: 0.5rem;
            position: absolute;
            left: 1.167rem;
            top: 2.667rem;
          }
        }
      }
    }
    .rightContent3 {
      width: 20.833rem;
      height: 8.083rem;
      background-color: #0f131f;
      margin-left: 46.083rem;
      margin-top: 0.417rem;
      .rightContent3top {
        width: 20.833rem;
        height: 2.5rem;
        margin-bottom: 0.125rem;
        border-bottom: 0.042rem solid #07090e;
        .rightContent3topleft {
          line-height: 2.5rem;
          width: 4rem;
          height: 1rem;
          font-family: SourceHanSansSC-Medium;
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 53px;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 1.167rem;
          border-right: 0.125rem solid #92b0e8;
          padding-right: 0.542rem;
        }
        .rightContent3topright {
          font-family: MicrosoftYaHei;
          font-size: 0.833rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 16px;
          letter-spacing: 0.083rem;
          color: #d6e9ff;
          margin-left: 0.5rem;
        }
      }
      .rightContent3content {
        width: 20.833rem;
        height: 4.5rem;
        // background-color: red;
        overflow: hidden;
        position: relative;
        .rightContent3contentone {
          height: 0.75rem;
          .rightContent3contenttop {
            font-family: MicrosoftYaHeiLight;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
            margin-top: 0.917rem;
          }
          img {
            width: 0.792rem;
            height: 0.792rem;
            position: absolute;
            left: 1.167rem;
            top: 1.042rem;
          }
        }
        .rightContent3contenttwo {
          height: 0.75rem;
          margin-top: 1.5rem;
          .rightContent3contentfoot {
            height: 0.708rem;

            font-family: MicrosoftYaHei;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 1.625rem;
            letter-spacing: 0.042rem;
            color: #bdd2f8;
            margin-left: 2.25rem;
          }
          img {
            width: 0.708rem;
            height: 0.5rem;
            position: absolute;
            left: 1.167rem;
            top: 3.4rem;
          }
        }
      }
    }
    .rightContent4 {
      width: 20.833rem;
      height: 2.5rem;
      background-color: #0f131f;
      margin-left: 46.083rem;
      margin-top: 0.417rem;
      .rightContent4top {
        .rightContent4topleft {
          line-height: 2.5rem;
          width: 4rem;
          height: 1rem;
          font-family: SourceHanSansSC-Medium;
          font-size: 1rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 53px;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 1.167rem;
          border-right: 0.125rem solid #92b0e8;
          padding-right: 0.542rem;
        }
        .rightContent4topright {
          font-family: MicrosoftYaHei;
          font-size: 0.833rem;
          font-weight: normal;
          font-stretch: normal;
          // line-height: 16px;
          letter-spacing: 0.083rem;
          color: #d6e9ff;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
