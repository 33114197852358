<template>
  <!-- 关于麦迪克组件 -->
  <div class="bigone">
    <div id="instroducess" v-cloak>
      <!-- <Header @jumpId="getjump" /> -->
      <!-- 公司简介 -->
      <div class="companyInstroduce" id="comProfile" v-cloak>
        <div class="top" v-cloak>
          <div class="one">公司简介</div>
          <span class="two">Company profile</span>
        </div>
        <div class="word" v-cloak>
          <p class="wordone">
            北京麦迪克未来科技有限公司创立于 2014 年
            5月，是一家专注于完全自运营、提供一站式数字营销服务技术企业。
          </p>
          <p class="wordtwo">
            经过多年的发展和磨砺，企业规模不断壮大，除在北京设立公司外，已陆续在武汉，合肥开设分公司。
          </p>
          <p class="wordthree">7年来，年实际营收自运营消耗从1500万到40亿。</p>
        </div>
      </div>
      <!-- 业务范围 -->
      <div class="range" id="scopeofService" name="scopeofService" v-cloak>
        <div class="rangetitle">
          <span class="bigtext">业务范围</span>
          <span class="righttest">专注于字节系营销服务</span>
          <div class="english">Scope of business</div>
        </div>
        <div class="picture1">
          <span
            >信息流 <br />
            数字营销</span
          >
          <!-- <div class="englishtext">Information flow <br />service</div> -->
          <div class="foot">信息流效果代理商</div>
        </div>
        <div class="picture2">
          <span
            >电商<br />
            业务</span
          >
          <!-- <div class="englishtext2">
          E-commerce <br />&nbsp;&nbsp;&nbsp;business
        </div> -->
          <div class="foot2">抖音DP服务商</div>
        </div>
        <div class="test">
          <div class="test1">
            专注字节7年，核心代理商<br />
            &emsp;因为专注，所以专业 !
          </div>
          <div class="test2">
            新生业务力量，重点布局 <br />
            &emsp;&emsp;保持初心再出发！
          </div>
        </div>
      </div>
      <!-- 公司文化 -->
      <div class="companyCuture" id="corCulture" v-cloak>
        <div class="companyCutureTitle">
          <span class="cuture">公司文化</span>
          <div class="cutureEnglish">Corporate culture</div>
        </div>
        <div class="circleTob">
          <div class="circle1">
            <span class="circle1text">专注</span>
            <!-- 第2个小圆 -->
            <!-- <span class="circle2"> -->
            <!-- 第3个圆 -->
            <!-- <span class="circle3"></span> -->
            <!-- </span> -->
          </div>
          <div class="circle1">
            <span class="circle1text">坦诚</span>

            <!-- <span class="circle2">

            <span class="circle4"></span>
          </span> -->
          </div>
          <div class="circle1">
            <span class="circle1text">高效</span>

            <!-- <span class="circle2">
            <span class="circle3"></span>
          </span> -->
          </div>
          <div class="circle1">
            <span class="circle1text">有温度</span>

            <!-- <span class="circle2">
            <span class="circle4"></span>
          </span> -->
          </div>
        </div>
        <div class="companyCuturecontent">
          <div class="companyCuturecontenone">
            <span class="firstStagecircle"></span>
            <span class="firstStage">
              趋同的价值观，造就高效、坦诚的团队，形成高效协同效率。
            </span>
          </div>
          <div class="companyCuturecontenone">
            <span class="firstStagecircle"></span>
            <span class="firstStage">
              沉淀一支字节系最早期的信息流广告优化师及短视频制作团队，团队稳定超具竞争力。
            </span>
          </div>
          <div class="companyCuturecontenone">
            <span class="firstStagecircle"></span>
            <span class="firstStage">
              务实的团队品格、扎实的综合业务服务能力，与平台、合作伙伴共同成长、共赢。
            </span>
          </div>
        </div>
        <!-- <div class="undeline"></div> -->
      </div>
      <!-- 团队风采 -->
      <div class="teamsMien" id="teamSpirit" v-cloak>
        <div class="teamsMientitle">
          <span class="teamsMientitletop">团队风采</span>
          <div class="teamsMientitlefoot">Team style</div>
        </div>
        <!-- 上划线 -->
        <div class="teamsMientitletopline"></div>
        <!-- <div class="teamsMienpicture">
        <div class="teamsMienpicture1">
          <img src="../../assets/1111.jpg" alt="" />
          <div class="teamsMienpicture1top">麦迪克公司年会</div>
          <span class="teamsMienpicture1foot">此处为介绍文案</span>
        </div>
        <div class="teamsMienpicture2">
          <img src="../../assets/222.jpg" alt="" />
          <div class="teamsMienpicture2top">年中颁奖会</div>
          <span class="teamsMienpicture2foot">此处为介绍文案</span>
        </div>
        <div class="teamsMienpicture3">
          <img src="../../assets/333.jpg" alt="" />
          <div class="teamsMienpicture3top">红毯走秀</div>
          <span class="teamsMienpicture3foot">此处为介绍文案</span>
        </div>
      </div> -->

        <!-- <div class="teamsMienpicture">
        <div class="iconleft" @click="zuohua">

          <img
            class="iconleft1"
            src="../../assets/关于麦迪克/04团队风采/1.png"
            alt=""
          />
        </div>

        <ul :style="{ left: calleft + 'px' }">
          <li v-for="(item, index) in superurl" :key="index">
            <img :src="item.img" alt="" />
          </li>
        </ul>
        <ul :style="{ left: calleft + 'px' }">
          <li v-for="(item, index) in superur2" :key="index">
            <div class="teamsMienpicturetop">{{ item.top }}</div>
            <span class="teamsMienpicturefoot">{{ item.foot }}</span>
          </li>
        </ul>
        <div class="iconright" @click="youhua">
          <img
            class="iconright1"
            src="../../assets/关于麦迪克/04团队风采/1-1.png"
            alt=""
          />
        </div>
      </div> -->
        <div class="teamsMienpicture">
          <!-- 左滑图标 -->
          <div class="iconleft" @click="zuohua">
            <img
              class="iconleft1"
              src="../../assets/关于麦迪克/04团队风采/1.png"
              alt=""
            />
          </div>
          <div
            class="Containt"
            v-on:mouseover="stopmove()"
            v-on:mouseout="move()"
          >
            <ul :style="{ left: calleft + 'px' }" class="imgBoxoul">
              <li
                v-for="(item, index) in dataLists"
                :key="index"
                @click="gotodetails(item.id)"
                ref="lis"
              >
                <img :src="'https://newoss.mdkbj.com/' + item.pic" alt="" />
              </li>
            </ul>
            <ul :style="{ left: calleft + 'px' }">
              <li v-for="(item, index) in dataLists" :key="index">
                <div class="teamsMienpicturetop">{{ item.title }}</div>
                <span class="teamsMienpicturefoot">{{ item.content }}</span>
              </li>
            </ul>
          </div>
          <!-- 右滑图标 -->
          <div class="iconright" @click="youhua">
            <img
              class="iconright1"
              src="../../assets/关于麦迪克/04团队风采/1-1.png"
              alt=""
            />
          </div>
        </div>
        <div class="teamsMientitleunderline"></div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>

import Footer from '../Layout/components/footer.vue'
// import Header from '../Layout/components/header.vue'
export default {
  components: {
    // Header,
    Footer
  },
  data () {
    return {

      calleft: 0,
      dataLists: [],
      dataList: [{
        id: 1,
        url: '',
        img: require('../../assets/1111.jpg')
      },

      {
        id: 2,
        url: '',
        img: require('../../assets/222.jpg')
      },
      {
        id: 3,
        url: '',
        img: require('../../assets/333.jpg')
      },
      {
        id: 4,
        url: '',
        img: require('../../assets/1111.jpg')
      },

      {
        id: 5,
        url: '',
        img: require('../../assets/222.jpg')
      },
      {
        id: 6,
        url: '',
        img: require('../../assets/333.jpg')
      }],

      superur2: [
        {
          top: '麦迪克公司年会',
          foot: '此处为介绍文案'
        },
        {
          top: '年中颁奖会',
          foot: '此处为介绍文案'
        },
        {
          top: '红毯走秀',
          foot: '此处为介绍文案'

        },
        {
          top: '麦迪克公司年会',
          foot: '此处为介绍文案'
        },
        {
          top: '年中颁奖会',
          foot: '此处为介绍文案'
        },
        {
          top: '红毯走秀',
          foot: '此处为介绍文案'

        }

      ]
    }
  },

  created () {
    this.move()
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  mounted () {
    var data = sessionStorage.getItem('toIds')
    console.log('标签id', data)
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll)
    })
    // 获取轮播图内容
    this.Clist()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
    window.pageYOffset = 0
    // console.log('页面离开了', window.pageYOffset)
  },
  methods: {
    // 挂载新方法

    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    },
    getlocal () {
      // 从本地获取 找到锚点id
      const selectId = localStorage.getItem('toId')
      // 读取锚点id的dom元素
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView() // 跳转到对应的锚点
        localStorage.setItem('toId', '') // 清空我们的锚点:重新存一次覆盖
      }
    },
    // 自动移动
    // move () {
    //   this.timer = setInterval(this.starmove, 2000)
    // },
    // 开始移动
    // starmove () {
    //   this.calleft -= 240

    //   if (this.calleft < -720) {
    //     this.calleft = 0
    //   }
    // },

    // 鼠标悬停时停止移动
    // stopmove () {
    //   clearInterval(this.timer)
    // },
    // 点击按钮左移
    // zuohua () {
    //   this.calleft -= 240
    //   if (this.calleft < -720) {
    //     this.calleft = 0
    //   }
    // },
    // 点击按钮右移
    // youhua () {
    //   this.calleft += 240
    //   if (this.calleft > 0) {
    //     this.calleft = -720
    //   }
    // }

    // 移动
    move () {
      this.timer = setInterval(this.starmove, 20)
    },
    // 开始移动
    /*
      starmove () {
      this.calleft -= 1.2//* this.speed
      if (this.calleft <= -1200) {
        this.calleft = 0
      }
    },
    */
    starmove () {
      this.calleft -= 1.2//* this.speed
      if (this.calleft <= -3120) {
        this.calleft = 0
      }
    },
    // 鼠标悬停时停止移动
    stopmove () {
      clearInterval(this.timer)
    },
    /* zuohua () {
      this.calleft -= 234// this.speed = 1
      if (this.calleft <= -1200) {
        this.calleft = 0
      }
    }, */
    // 点击按钮左移
    zuohua () {
      this.calleft -= 234// this.speed = 1
      if (this.calleft <= -3120) {
        this.calleft = 0
      }
    },
    /*
     // 点击按钮右移
    youhua () { // this.speed = -1
      this.calleft += 252
      if (this.calleft >= 0) {
        this.calleft = -720
      }
    },
    */
    // 点击按钮右移
    youhua () { // this.speed = -1
      this.calleft += 250
      if (this.calleft >= 0) {
        this.calleft = -3120
      }
    },
    // 轮播图方法
    // 轮播图内容
    // 轮播图内容
    Clist () {
      var arr = {
        page: 1, //
        pageSize: 9, //
        type: 3, // 1轮播图  2荣誉
        title: '', // 标题
        deleted: ''// 是否可用 1可用 2不可用
      }
      this.server.Carousel(arr).then(res => {
        // var arr = []
        // var item = res.data.data.list
        this.dataLists = res.data.data.list
        // console.log('轮播图内容', item)
        // for (var i = 0; i < item.length; i++) {
        //   arr.push(item[i])
        //   if (arr.length % 4 === 0) {
        //     this.dataLists.push(arr)
        //     arr = []
        //   } else if (arr.length % 4 !== 0 && i === item.length - 1) {
        //     this.dataLists.push(arr)
        //     arr = []
        //   }
        // }
      })
    }
  }
}
</script>

<style lang="less">
.bigone {
  width: 100%;
  height: 100%;
}
.companyInstroduce {
  width: 80rem;
  height: 42.5rem;

  background: url("../../assets/关于麦迪克/01公司简介/01公司简介底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;

  .top {
    // width: 226px;
    height: 3.333rem;
    // background-color: red;
    // padding-top: 262px;
    margin-top: 8.417rem;
    margin-left: 17.417rem;
    border-left: 0.375rem solid white;
    overflow: hidden;

    // .border {
    //   width: 9px;
    //   height: 80px;
    //   background-color: #ffffff;
    //   border: solid 0px #070002;
    // }
    .one {
      // width: 165px;
      // // height: 38px;
      // font-family: SourceHanSansSC-Medium;
      // font-size: 40px;
      // font-weight: normal;

      // line-height: 16px;
      // letter-spacing: 2px;
      // color: #ffffff;

      // width: 165px;
      // height: 38px;
      font-family: SourceHanSansSC-Medium;
      font-size: 1.583rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.083rem;
      color: #ffffff;
      margin-left: 1.083rem;
    }
    .two {
      // width: 191px;
      // height: 21px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;

      line-height: 0.667rem;
      letter-spacing: 0.125rem;
      color: #9fafdf;

      margin-left: 1.083rem;
    }
  }
  .word {
    width: 45.208rem;
    height: 19.417rem;
    border: solid 1px #9fafdf;
    margin-left: 17.417rem;
    margin-top: 2.125rem;
    p {
      // margin-left: 2.625rem;
      margin: auto;
      margin-left: 3.3rem;

      width: 38.958rem;

      font-family: SourceHanSansSC-Light;
      font-size: 1.167rem;
      font-weight: normal;
      font-stretch: normal;

      letter-spacing: 0px;
      color: #ffffff;
    }
    .wordone {
      width: 38.958rem;
      height: 3.292rem;
      margin-top: 2.75rem;
      margin-bottom: 2.542rem;
      line-height: 2.083rem;
    }
    .wordtwo {
      margin-top: 2.542rem;
      line-height: 2.083rem;
      margin-bottom: 2.542rem;
      width: 38.917rem;
      height: 3.292rem;
    }
    .wordthree {
      height: 1.208rem;

      // font-size: 1.25rem;

      line-height: 2.208rem;
      letter-spacing: 0px;
    }
  }
}
.range {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/关于麦迪克/02业务范围/01业务范围-底图.png")
    no-repeat 0 0 / contain;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .rangetitle {
    width: 21.875rem;
    height: 3.333rem;

    margin-left: 17.417rem;
    margin-top: 5.458rem;
    border-left: 0.375rem solid white;
    margin-bottom: 1.083rem;
    .bigtext {
      // width: 162px;
      // height: 38px;
      font-family: SourceHanSansSC-Medium;
      font-size: 1.583rem;

      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.083rem;
      color: #ffffff;
      padding-left: 1.042rem;
      padding-top: 0.083rem;
    }
    .righttest {
      // width: 294px;
      // height: 27px;
      font-family: SourceHanSansSC-Light;
      font-size: 1.083rem;
      font-weight: normal;
      font-stretch: normal;
      padding-left: 0.875rem;
      letter-spacing: 0.083rem;
      color: #ffffff;
    }
    .english {
      padding-left: 1.042rem;

      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      letter-spacing: 0.125rem;
      color: #9fafdf;
      padding-bottom: 0.125rem;
    }
  }
  .picture1 {
    width: 22rem;
    height: 22rem;
    background: url("../../assets/关于麦迪克/02业务范围/3.png") no-repeat 0 0 /
      contain;
    margin-left: 15.208rem;
    text-align: center;
    position: absolute;
    overflow: hidden;
    span {
      height: 3.458rem;
      font-family: MicrosoftYaHei;
      font-size: 1.333rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2.333rem;
      letter-spacing: 0.167rem;
      color: #ffffff;
      position: absolute;
      left: 7.958rem;
      top: 8.6rem;
    }
    .englishtext {
      // width: 108px;
      // height: 26px;
      font-family: MicrosoftYaHeiLight;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 18px;
      // letter-spacing: 1px;
      color: #7d89b4;
      position: absolute;
      left: 200px;
      top: 310px;
    }
    .foot {
      height: 1.292rem;
      font-family: MicrosoftYaHei;
      font-size: 1.167rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.917rem;
      letter-spacing: 0.083rem;
      color: #e6e6e6;
      margin-top: 19.333rem;
    }
  }
  .picture2 {
    width: 22rem;
    height: 22rem;
    background: url("../../assets/关于麦迪克/02业务范围/3.png") no-repeat 0 0 /
      contain;
    margin-top: 0.208rem;
    margin-left: 42.5rem;
    position: absolute;
    overflow: hidden;
    span {
      // width: 74px;
      height: 3.458rem;
      font-family: MicrosoftYaHei;
      font-size: 1.333rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 56px;
      letter-spacing: 0.167rem;
      color: #ffffff;
      position: absolute;
      left: 9.375rem;
      top: 8.917rem;
    }
    .englishtext2 {
      // width: 80px;
      height: 25px;
      font-family: MicrosoftYaHeiLight;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 18px;
      letter-spacing: 1px;
      color: #7d89b4;
      position: absolute;
      left: 210px;
      top: 310px;
    }
    .foot2 {
      height: 1.292rem;
      font-family: MicrosoftYaHei;
      font-size: 1.167rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.917rem;
      letter-spacing: 2px;
      color: #e6e6e6;
      margin-top: 19.333rem;
      margin-left: 6.792rem;
    }
  }
  .test {
    .test1 {
      height: 2.375rem;
      font-family: MicrosoftYaHeiLight;
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 35px;
      letter-spacing: 0.083rem;
      color: #e6e6e6;
      margin-left: 20.292rem;
      margin-top: 22.917rem;
    }
    .test2 {
      height: 2.333rem;
      font-family: MicrosoftYaHeiLight;
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 1.458rem;
      letter-spacing: 0.042rem;
      color: #e6e6e6;
      margin-left: 48rem;
      margin-top: -2.375rem;
    }
  }
}
.companyCuture {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/关于麦迪克/03公司文化/03公司文化底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  .companyCutureTitle {
    // width: 245px;
    height: 3.333rem;
    margin-top: 6.833rem;
    margin-left: 17.417rem;
    border-left: 0.375rem solid white;
    .cuture {
      font-family: SourceHanSansSC-Medium;
      font-size: 1.583rem;
      font-weight: normal;
      font-stretch: normal;
      margin-left: 1.042rem;
      letter-spacing: 0.083rem;
      color: #ffffff;
    }
    .cutureEnglish {
      // width: 210px;
      // height: 21px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;

      letter-spacing: 0.125rem;
      color: #9fafdf;
      padding-left: 1.042rem;
    }
  }
  .circleTob {
    width: 40.667rem;
    height: 7.417rem;
    // background-color: green;
    margin-top: 1.875rem;
    margin-left: 20.292rem;
    display: flex;

    .circle1 {
      width: 6.875rem;
      height: 7.292rem;
      text-align: center;
      line-height: 6.25rem;
      margin-right: 4rem;

      background: url("../../assets/关于麦迪克/03公司文化/4.png") no-repeat 0 0 /
        contain;
      .circle1text {
        // width: 71px;
        // height: 34px;
        font-family: SourceHanSansSC-Regular;
        font-size: 1.417rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0.083rem;
        color: #ffffff;
      }
      .circle2 {
        display: block;
        width: 1.083rem;
        height: 1.083rem;
        // background-color: green;
        // background: url("../../assets/关于麦迪克/03公司文化/1-1.png") no-repeat
        //   0 0 / contain;
        margin-left: 2.5rem;
        margin-top: -0.917rem;
        overflow: hidden;

        .circle3 {
          display: block;
          width: 0.333rem;
          height: 0.333rem;
          border-radius: 50%;
          background-color: #7589ff;
          margin-top: 0.375rem;
          margin-left: 0.375rem;
        }
        .circle4 {
          display: block;
          width: 0.333rem;
          height: 0.333rem;
          border-radius: 50%;
          background-color: #cad2ff;
          margin-top: 0.417rem;
          margin-left: 0.375rem;
        }
      }
    }
    .circle1:hover {
      background: url("../../assets/关于麦迪克/03公司文化/5.png") no-repeat 0 0 /
        contain;
    }
  }
  .companyCuturecontent {
    width: 46.417rem;
    height: 9.833rem;
    // background-color: #49538f;
    background: url("../../assets/关于麦迪克/03公司文化/3-3.png") no-repeat 0 0 /
      contain;

    margin-left: 17.417rem;
    margin-top: 3.167rem;
    margin-bottom: 0.542rem;
    // display: flex;
    .companyCuturecontenone {
      display: flex;

      height: 1rem;
      padding-top: 2.167rem;
      margin-left: 2.167rem;

      .firstStage {
        display: block;
        height: 1rem;
        font-family: SourceHanSansSC-Light;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 1px;
        color: #ffffff;
      }
      .firstStagecircle {
        display: block;
        width: 0.625rem;
        height: 0.625rem;
        line-height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
        margin-right: 1.125rem;
        margin-top: 0.333rem;
      }
    }
  }
  .undeline {
    width: 46.417rem;
    height: 0.083rem;
    // background: url("../../assets/关于麦迪克/03公司文化/3-1.png") no-repeat 0 0 /
    //   cover;
    margin-left: 16.75rem;
  }
}
.teamsMien {
  width: 80rem;
  height: 29.417rem;
  background: url("../../assets/关于麦迪克/04团队风采/04团队风采底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  .teamsMientitle {
    width: 8.333rem;
    height: 3.333rem;
    margin-left: 17.417rem;
    margin-top: 4.25rem;
    border-left: 0.375rem solid white;
    text-align: center;
    margin-bottom: 2.083rem;

    .teamsMientitletop {
      // width: 163px;
      // height: 38px;
      display: block;
      font-family: SourceHanSansSC-Medium;
      font-size: 1.583rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.083rem;
      color: #ffffff;
      padding-left: 0.833rem;
    }
    .teamsMientitlefoot {
      // width: 127px;
      height: 0.875rem;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.125rem;
      color: #9fafdf;
      // margin-top: 15px;
      // padding-left: 20px;
      margin-left: -0.5rem;
    }
  }
  .teamsMientitletopline {
    width: 45.625rem;
    height: 0.167rem;
    background: url("../../assets/关于麦迪克/04团队风采/2.png") no-repeat 0 0 /
      contain;
    margin-left: 17.208rem;
    margin-bottom: 0.833rem;
  }
  .teamsMientitleunderline {
    width: 45.625rem;
    height: 0.167rem;
    background: url("../../assets/关于麦迪克/04团队风采/2.png") no-repeat 0 0 /
      contain;
    margin-left: 17.208rem;
    margin-top: 1.167rem;
  }
  .teamsMienpicture {
    width: 45.833rem;
    height: 12.083rem;
    // background-color: yellow;
    display: flex;
    margin-left: 17.208rem;
    // overflow: hidden;
    position: relative;

    .teamsMienpicture1 {
      width: 14.625rem;
      height: 12.083rem;
      margin-right: 0.958rem;

      img {
        width: 14.625rem;
        height: 8.833rem;
        background-color: violet;
        margin-bottom: 0.958rem;
      }
      .teamsMienpicture1top {
        // width: 160px;
        height: 0.917rem;
        font-family: MicrosoftYaHei;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0.042rem;
        color: #ffffff;
        margin-bottom: 0.583rem;
      }
      .teamsMienpicture1foot {
        width: 4.958rem;

        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0.042rem;
        color: #ffffff;
      }
    }
    .teamsMienpicture2 {
      width: 14.625rem;
      height: 12.083rem;
      margin-right: 0.958rem;

      img {
        width: 14.625rem;
        height: 8.833rem;
        // background-color: green;
        margin-bottom: 0.958rem;
      }
      .teamsMienpicture2top {
        height: 0.917rem;
        font-family: MicrosoftYaHei;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.25rem;
        letter-spacing: 0.042rem;
        color: #ffffff;
        margin-bottom: 0.583rem;
      }
      .teamsMienpicture2foot {
        height: 0.667rem;
        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0.042rem;
        color: #ffffff;
      }
    }
    .teamsMienpicture3 {
      width: 14.625rem;
      height: 12.083rem;
      // margin-right: 20px;

      img {
        width: 14.625rem;
        height: 8.833rem;
        background-color: purple;
        margin-bottom: 0.958rem;
      }
      .teamsMienpicture3top {
        height: 0.917rem;
        font-family: MicrosoftYaHei;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.25rem;
        letter-spacing: 0.042rem;
        color: #ffffff;
        margin-bottom: 0.583rem;
      }
      .teamsMienpicture3foot {
        height: 16px;
        font-family: MicrosoftYaHei;
        font-size: 0.667rem;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0.042rem;
        color: #ffffff;
      }
    }
    .iconleft {
      .iconleft1 {
        width: 1.042rem;
        height: 2.333rem;
        // background-color: red;
        // margin-left: -20px;
        // margin-top: 85px;
        position: absolute;
        left: -4.167rem;
        top: 2.5rem;
      }
    }
    .iconright {
      .iconright1 {
        width: 1.042rem;
        height: 2.333rem;
        // background-color: red;
        // margin-left: 1137px;
        // margin-top: 85px;
        position: absolute;
        right: -4.167rem;
        top: 2.5rem;
      }
    }
    .Containt {
      position: relative;
      overflow-y: auto;
      width: 45.833rem;
      height: 12.083rem;
      overflow: hidden;
      margin: 0 auto;
      transition: all 0.3s;

      ul {
        margin: 0 auto;

        height: 12.083rem;
        position: absolute;
        left: 0px;
        cursor: pointer;
        display: flex;
        left: 17.208rem;
        overflow: hidden;
        z-index: 10;

        // background-color: red;
        li {
          width: 14.625rem;
          height: 12.083rem;
          margin-right: 0.792rem;
          overflow: hidden;
          img {
            width: 14.625rem;
            height: 8.833rem;
          }
          .teamsMienpicturetop {
            height: 0.917rem;
            font-family: MicrosoftYaHei;
            font-size: 0.917rem;
            font-weight: normal;
            font-stretch: normal;

            letter-spacing: 0.042rem;
            color: #ffffff;
            margin-top: 9.583rem;
            margin-bottom: 0.501rem;
          }
          .teamsMienpicturefoot {
            width: 4.958rem;

            font-family: MicrosoftYaHei;
            font-size: 0.667rem;
            font-weight: normal;
            font-stretch: normal;

            letter-spacing: 0.042rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
