(function () {
  // rem适配，html跟字体的大小
  // 核心：设备宽 / 80
  const setFont = function () {
    // 获取html
    const html = document.documentElement// document.querySelector('html');
    // 获取宽度
    let width = html.clientWidth
    // 判断
    if (width <= 1024) width = 1024
    if (width >= 1920) width = 1920
    // 计算跟字体大小
    const fontSize = width / 80 + 'px'
    // 设置给html
    html.style.fontSize = fontSize
  }
  setFont()
  // 设备窗口尺寸发生改变：resize
  window.addEventListener('resize', function () {
    setFont()
  })
})()
