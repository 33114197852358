<template>
  <!-- 公共布局 -->
  <div class="headerNav">
    <!-- 载头部  -->
    <Header @jumpId="getjump" />
    <RouterLink to="/">
      <!-- <div class="logo">
        <img src="../../assets/导航栏/logo.png" alt="官网" /> -->

      <!-- <ul class="nav">
          <li class="textTap">
            <RouterLink to="/"> 首&nbsp;&nbsp;页 </RouterLink>
          </li>
          <li>
            <RouterLink to="/instroduce"> 关于麦迪克 </RouterLink>
            <div class="isShow">
              <ul>
                <li><a href="">公司简介</a></li>
                <li><a href="">业务范围</a></li>
                <li><a href="">公司文化</a></li>
                <li><a href="">团队风采</a></li>
              </ul>
            </div>
          </li>
          <li><RouterLink to="/service"> 业务服务 </RouterLink></li>
          <li><RouterLink to="/invite"> 招贤纳士 </RouterLink></li>
          <li><RouterLink to="/contact"> 联系我们 </RouterLink></li>
        </ul> -->
      <!-- <el-menu
          active-text-color="#ffffff"
          background-color="#181d2e"
          collapse
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          text-color="#fff"
        >
          <el-menu-item
            style="width: 133px font-size: 16px;"
            ref="shouye"
            class="son1"
            index="1"
            >首&nbsp;&nbsp;页</el-menu-item
          >
          <el-submenu index="2" style="width: 133px font-size: 16px;">
            <template slot="title">关于麦迪克</template>
            <el-menu-item class="son" index="2-1">公司简介</el-menu-item>
            <el-menu-item class="son" index="2-2">业务范围</el-menu-item>
            <el-menu-item class="son" index="2-3">公司文化</el-menu-item>
            <el-menu-item class="son" index="2-4">团队风采</el-menu-item>
          </el-submenu>
          <el-submenu
            style="width: 133px font-size: 16px;"
            index="3"
            class="son"
          >
            <template slot="title">业务服务</template>
            <el-menu-item index="3-1">过往业绩</el-menu-item>
            <el-menu-item index="3-2">服务模式</el-menu-item>
            <el-menu-item index="3-3">行业案例</el-menu-item>
            <el-menu-item index="3-4">公司荣誉</el-menu-item>
          </el-submenu>
          <el-submenu
            style="width: 133px font-size: 16px;"
            index="4"
            class="son"
          >
            <template slot="title">招贤纳士</template>
            <el-menu-item index="4-1">过往业绩</el-menu-item>
            <el-menu-item index="4-2">服务模式</el-menu-item>
            <el-menu-item index="4-3">行业案例</el-menu-item>
            <el-menu-item index="4-4">公司荣誉</el-menu-item>
          </el-submenu>
        </el-menu> -->
      <!-- </div> -->
    </RouterLink>

    <main>
      <router-view></router-view>
    </main>
    <!--  载尾部 -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from './components/header.vue'
// import Footer from './components/footer.vue'
export default {
  components: {
    Header
    // Footer
  },
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    }
  },
  watch: {
    // 解决hash模式下切换路由滚动条位置不变的bug
    $route: function (to, from) {
      document.body.scrollTop = 0

      document.documentElement.scrollTop = 0
    }

  },
  created () {
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  mounted () { },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    },
    getlocal () {
      // 找到锚点id
      const selectId = localStorage.getItem('toId')
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    }
    // change () {
    //   console.log('111', this.$refs.shouye)
    //   this.$refs.shouye.$el.style.borderBottom = 'white solid 3px'
    // }

  }
}

</script>

<style scoped lang="less">
/* 公共布局 */
/* .headerNav {
  width: 1920px;
  height: 60px;
  background-color: #181d2e;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.1);
  border: solid 0px #070002;
} */
/* logo */
/* .logo {
  height: 60px;
  display: flex;
  align-content: center;
  justify-content: space-around;
} */
/* 图片 */

/* img {
  margin: auto 0;
  margin-left: 360px;
} */
/* 导航栏 */
/* .nav {
  width: 719px;
  height: 60px;
  display: block;
  display: flex;
  justify-content: center;
  list-style: none;
  line-height: 60px;
  font-size: 16px;

  margin-left: 375px;
} */

/* 导航栏下面的li */
/* .nav > li {
  width: 133px;
  height: 60px;
  margin-left: 41px;
} */
/* 鼠标移动导航栏中的li 显示背景颜色和下拉框 */
/* .nav > li:hover .isShow {
  background-color: #374570;
  border-bottom: white solid 3px;
  display: block;
} */
/* a {
  color: #409eff;
  color: white;
  text-decoration: none;
} */
/* 去掉跳转路由留下的下划线 */
/* .router-link-active {
  text-decoration: none;
} */
/* 首页文字间距 */
/* .textTap {
  letter-spacing: 10px;
  width: 135px;
  border-bottom: white solid 3px;
  text-align: center;
} */
/* .isShow {
  width: 133px;
  height: 240px;
  display: none;
  display: none;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.2s 0.1s;
} */

/* .nav > li:hover {
  width: 133px;
  height: 240px;
  display: block;
} */

/* 关于麦迪克样式 */
/* .isShow ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: none;
}
.isShow ul {
  width: 133px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */
/* .el-menu-demo {
  width: 719px;
  height: 60px;
  font-size: 16px;
  border: none;

  margin-left: 375px;
}
.el-menu-demo .son1 {
  width: 135px;
  border-bottom: white solid 3px;
} */
</style>
<style>
/* .headerNav .el-submenu:hover {
  border-bottom: white solid 3px;
} */
</style>
