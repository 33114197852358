import object from './request'
const service = object

const server = {
  // 轮播图
  Carousel (data) {
    return service.post('/mdkweb/mdk-list/homepage-by', data)
  },
  // 招聘信息
  recruitList (data) {
    return service.post('/mdkweb/mdk-list/recruit-list', data)
  }
}
export default server
