<template>
  <!-- 这里是招贤纳士页面 -->
  <div class="bigone">
    <div v-cloak>
      <!-- <Header @jumpId="getjump" /> -->
      <div class="invite">
        <!-- 招聘信息 -->
        <!--    <div class="inviteTob" > -->
        <div class="inviteTob" id="recruitmentInformation">
          <div class="jobTitle">
            <div class="jobTitletob">招聘信息</div>
            <span class="jobTitlefoot">recruitment information</span>
          </div>
          <div class="invitetab">
            <div class="icon" @click="chooseInput">
              <img src="../../assets/招贤纳士/1.png" alt="" />
              <input readonly class="select-input" v-model="cityId" />
              <i
                class="icon2"
                :class="[
                  'down-img',
                  caretFlag == true
                    ? 'el-icon-caret-top'
                    : 'el-icon-caret-bottom',
                ]"
              ></i>
            </div>

            <ul v-if="caretFlag" class="selectlist">
              <li
                class="select-li"
                @click="chooseCity(item)"
                v-for="(item, i) in cityArr"
                :key="i"
              >
                {{ item.city }}
              </li>
            </ul>

            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="item in tabArr"
                :key="item.id"
                :label="item.title"
                :name="item.id"
              >
                <!--  :header-cell-style="{ backgroundColor: red }" -->
                <el-table
                  fit
                  :header-cell-style="{ 'text-align': 'center' }"
                  :data="tableData"
                  style="width: 100%; margin-bottom: 20px"
                >
                  <el-table-column
                    v-for="(item, i) in propData"
                    :key="i"
                    :prop="item.prop"
                    align="center"
                    :label="item.label"
                    :width="item.width"
                  >
                  </el-table-column>

                  <el-table-column label="操作" align="center">
                    <!--    :class="currentIndex === scope.$index ? 'colorIndex' : ''" -->
                    <template slot-scope="scope">
                      <el-button
                        ref="resume"
                        size="mini"
                        :class="
                          onlyResume.indexOf(scope.row.id) != -1
                            ? 'colorIndex'
                            : ''
                        "
                        @click="handleresume(scope.$index, scope.row)"
                        >简历投递</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <el-tab-pane
            ref="tabClick1"
            @click.native="tabClick"
            label="优化"
            name="first"
            >优化</el-tab-pane
          > -->
                <!-- <el-tab-pane label="销售" name="second">销售</el-tab-pane>
          <el-tab-pane label="人事" name="third">人事</el-tab-pane>
          <el-tab-pane label="助理" name="fourth">助理</el-tab-pane>
          <el-tab-pane label="技术" name="five">技术</el-tab-pane> -->
                <el-pagination
                  background
                  :page-size="sendList.pageSize"
                  @current-change="handleCurrentChange"
                  layout="prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </el-tab-pane>
            </el-tabs>
            <div id="beneJump"></div>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            width="20%"
            :before-close="handleClose"
            style="backgroundcolor: '#1f2235'"
          >
            <template slot="title">
              <div style="fontsize: 18px; color: #807863">
                请&nbsp;把&nbsp;简&nbsp;历&nbsp;投&nbsp;递&nbsp;到&nbsp;:
              </div>
            </template>
            <span class="textColor"
              ><br />
              <!-- <h2 style="fontsize: 18px; color: #807863">
              &emsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ message }}
            </h2> -->
              <input
                type="text"
                v-model="message"
                disabled="disabled"
                style="
                  width: 100%;
                  text-align: center;
                  background-color: transparent;
                  color: #807863;
                  fontsize: 18px;
                  border: 0px;
                "
              />
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button type="info" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!-- 公司福利 -->
        <div class="welfare">
          <div class="welfaretitle">
            <div class="welfaretitletob">公司福利</div>
            <span class="welfaretitlefoot">Company benefits</span>
          </div>
          <div class="welfarepicture">
            <ul>
              <li>
                <img
                  class="img1"
                  src="../../assets/招贤纳士/全勤奖.png"
                  alt=""
                />
                <div class="img11">全勤奖金</div>
                <span class="img111"></span>
                <span class="img1111"></span>
              </li>
              <li>
                <img
                  class="img2"
                  src="../../assets/招贤纳士/双月之星.png"
                  alt=""
                />
                <div class="img22">双月之星</div>
                <span class="img111"></span>
                <span class="img1112"></span>
              </li>
              <li>
                <img
                  class="img3"
                  src="../../assets/招贤纳士/节日福利.png"
                  alt=""
                />
                <div class="img33">节日福利</div>
                <span class="img111"></span>
                <span class="img1111"></span>
              </li>
              <li>
                <img
                  class="img4"
                  src="../../assets/招贤纳士/出国游.png"
                  alt=""
                />
                <div class="img44">出国游</div>
                <span class="img111"></span>
                <span class="img1113"></span>
              </li>
              <li>
                <img
                  class="img5"
                  src="../../assets/招贤纳士/股权激励.png"
                  alt=""
                />
                <div class="img55">股权激励</div>

                <span class="img1113"></span>
              </li>
<!--              <li>-->
<!--                <img-->
<!--                  class="img6"-->
<!--                  src="../../assets/招贤纳士/六险一金.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="img66">社保公积金</div>-->
<!--                <span class="img111"></span>-->
<!--              </li>-->
<!--              <li>-->
<!--                <img-->
<!--                  class="img7"-->
<!--                  src="../../assets/招贤纳士/下午茶.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="img77">美味下午茶</div>-->
<!--                <span class="img111"></span>-->
<!--              </li>-->
<!--              <li>-->
<!--                <img-->
<!--                  class="img8"-->
<!--                  src="../../assets/招贤纳士/双月之星.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="img88">双月之星</div>-->
<!--                <span class="img111"></span>-->
<!--              </li>-->
<!--              <li>-->
<!--                <img-->
<!--                  class="img9"-->
<!--                  src="../../assets/招贤纳士/生日礼物.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="img99">生日礼品</div>-->
<!--                <span class="img111"></span>-->
<!--              </li>-->
<!--              <li>-->
<!--                <img-->
<!--                  class="img10"-->
<!--                  src="../../assets/招贤纳士/节日福利.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="img1010">节日福利</div>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
// import Header from '../Layout/components/header.vue'
import Footer from '../Layout/components/footer.vue'
export default {
  components: {
    // Header,
    Footer
  },
  data () {
    return {
      currentIndex: '',
      message: '',
      dialogVisible: false, // 控制弹层显示隐藏的变量
      total: 0,
      tableData: [], // 招聘信息数据的数组
      cityId: '北京', // 默认选中的城市
      caretFlag: false, // 控制ul显示与否
      activeName: '1',
      // 每一个tab切换页面
      tabArr: [
        {
          title: '优化',
          id: '1'
        },

        {
          title: '商务',
          id: '2'
        },
        {
          title: '人事',
          id: '3'
        },
        {
          title: '助理',
          id: '4'
        },
        {
          title: '技术',
          id: '5'
        },
        {
          title: '视频',
          id: '6'
        },
        {
          title: '电商',
          id: '7'
        }
      ],
      // 选择的城市
      cityArr: [
        {
          city: '北京',
          id: '1'
        },
        {
          city: '武汉',
          id: '2'
        },
        {
          city: '合肥',
          id: '3'
        }],
      // 招聘的表头
      propData: [
        {
          label: '职位名称',
          prop: 'job_title',
          width: ''
        },
        {
          label: '学历要求',
          prop: 'require',
          width: ''
        },
        {
          label: '工作年限',
          prop: 'years_working',
          width: ''
        },
        {
          label: '招聘人数',
          prop: 'number',
          width: ''
        },
        {
          label: '发布时间',
          prop: 'create_time',
          width: ''
        }
        // {
        //   label: '操作',
        //   prop: 'operation',
        //   width: ''
        // }
      ],
      sendList: {
        page: 1,
        pageSize: 3,
        type: 1, // 职位类型
        city: 1 // 城市类型
      },
      resumeArr: [],
      onlyResume: []
    }
  },
  created () {
    // this.move()
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  mounted () {
    this.getJobData()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
  },
  methods: {
    // 点击投递后 触发的事件
    handleresume (i, row) {
      // console.log('i,row', i, row)
      this.currentIndex = i
      // this.resumeArr.push(i)
      this.resumeArr.push(row.id)
      this.onlyResume = Array.from(new Set(this.resumeArr))
      // console.log('数组是什么', this.onlyResume)

      // console.log('当前点击的是谁', this.$refs.resume[i].$el)
      // this.$refs.resume[i].$el.style.backgroundColor = '#6e7076'
      this.dialogVisible = true
      // this.$refs.resume[i].$el.style.backgroundColor = '#6e7076'
      // console.log('这是谁', this.$refs.resume[i].$el.style.backgroundColor = '#6e7076')
      // console.log('这是谁', this.$refs.resume[i].$el)

      if (this.cityId === '北京') {
        this.message = 'wangyang-hrbp@maidike.top'
      }
      if (this.cityId === '武汉') {
        this.message = 'wangyang-hrbp@maidike.top'
      }
      if (this.cityId === '合肥') {
        this.message = 'wangyang-hrbp@maidike.top'
      }
    },
    // 控制弹层关闭的方法
    handleClose (done) {
      this.dialogVisible = false
    },
    // 招聘信息分页
    handleCurrentChange (val) {
      this.sendList.page = val
      this.getJobData()
    },

    // 获取招聘数据的方法, 掉接口的
    getJobData () {
      this.server.recruitList(this.sendList).then((res) => {
        console.log('招聘数据', res.data.data.list)
        this.tableData = res.data.data.list
        this.total = res.data.data.count - 0
      })
    },

    handleClick (val) {
      console.log('点击的是谁', val)
      // this.resumeArr = []
      // console.log('新数组', this.resumeArr) // 打印 为空
      // if (this.resumeArr.length === 0) {
      //   this.currentIndex = ''
      // }

      this.sendList.type = val.name - 0
      this.getJobData()
    },
    // tabClick () {
    //   console.log('点谁了', this.$refs.tabClick1)
    //   this.$refs.tabClick1.$el.style.width = 'width:97px'
    //   this.$refs.tabClick1.$el.style.height = 'height:32spx'
    //   this.$refs.tabClick1.$el.style.backgroundColor = 'red'
    //   console.log('点谁了', this.$refs.tabClick1.style)
    // },
    // 选择城市的方法
    chooseCity (item) {
      // this.resumeArr = []
      // if (this.resumeArr.length === 0) {
      //   this.currentIndex = ''
      // }
      this.cityId = item.city

      this.caretFlag = false // 控制图标的方向

      this.sendList.city = item.id - 0

      this.getJobData()

      // this.$refs.resume.forEach(() => {
      //   console.log('进来看看', this.$refs.resume)
      //   item.$el.style.backgroundColor = '#4e6296'
      // })
    },
    chooseInput () {
      this.caretFlag = !this.caretFlag
    },

    // 点击2级tab 切换到指定位置  锚点
    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    },
    getlocal () {
      // 找到锚点id
      const selectId = localStorage.getItem('toId')
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    }
  }
}
</script>

<style scoped lang="less">
.bigone {
  width: 100%;
  height: 100%;
}
.invite {
  width: 80rem;
  height: 58.667rem;
  background: url("../../assets/招贤纳士/底.png") no-repeat 0 0 / contain;
  .inviteTob {
    overflow: hidden;
    .jobTitle {
      // width: 322px;
      height: 3.333rem;
      // background-color: red;
      margin-left: 17.417rem;
      margin-top: 6.5rem;
      border-left: 0.375rem solid white;

      .jobTitletob {
        // width: 164px;
        // height: 38px;
        font-family: SourceHanSansSC-Medium;
        font-size: 1.583rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
        margin-left: 1.083rem;
      }
      .jobTitlefoot {
        // width: 286px;
        // height: 16px;
        font-family: SourceHanSansSC-Light;
        font-size: 0.833rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.125rem;
        color: #9fafdf;
        margin-left: 1.083rem;
      }
    }
  }
  .invitetab {
    width: 45.125rem;
    margin-left: 17.417rem;
    // margin-top: 1rem;
    overflow: hidden;
    .icon {
      width: 5.125rem;
      height: 2.333rem;
      display: flex;
      img {
        width: 1rem;
        height: 1.208rem;
        margin-left: 40rem;
        margin-top: 1.667rem;
      }
      .select-input {
        width: 2.458rem;
        height: 1.125rem;
        border: none;
        // font-size: 27px;
        cursor: pointer;
        text-align: center;
        // margin-left: 1019px;
        margin-top: 1.75rem;
        margin-left: 0.75rem;
        color: #fff;
        background-color: #0b0d15;

        color: "#ffffff";
      }
      .icon2 {
        margin-top: 1.667rem;
        margin-left: 0px;
      }
    }
    .selectlist {
      //ul属性
      width: 4.167rem;
      height: 4.167rem;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0.417rem 0;
      position: absolute;
      left: 58.333rem;
      top: 14.917rem;
      z-index: 9999;

      .select-li {
        font-size: 0.583rem;
        cursor: pointer;
        line-height: 1.25rem;
        color: #fff;
        letter-spacing: 0.208rem;
        transition: all 0.5s;
      }
    }
    .colorIndex {
      background-color: #6e7076 !important;
    }
  }
  .welfare {
    width: 50rem;
    height: 26.375rem;

    // background-color: skyblue;
    margin-left: 15rem;
    margin-top: 3rem;
    .welfaretitle {
      // width: 10.167rem;
      height: 3.333rem;
      // background-color: red;
      margin-left: 2.417rem;
      border-left: 0.375rem solid white;
      margin-bottom: 1.75rem;
      .welfaretitletob {
        // width: 162px;
        // height: 37px;
        font-family: SourceHanSansSC-Medium;
        font-size: 1.583rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
        margin-left: 1.083rem;
      }
      .welfaretitlefoot {
        // width: 209px;
        // height: 21px;
        font-family: SourceHanSansSC-Light;
        font-size: 0.833rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.125rem;
        color: #9fafdf;
        margin-left: 1.083rem;
      }
    }
    .welfarepicture {
      width: 50rem;
      height: 21.292rem;
      // background-color: green;
      overflow: hidden;

      ul {
        display: block;
        width: 44.333rem;
        height: 15.5rem;
        // background-color: yellow;
        margin-left: 3.583rem;
        // margin-top: 3.083rem;
        margin-top: 1rem;
        // overflow: hidden;

        li {
          width: 8.667rem;
          height: 7.75rem;
          // background-color: blue;
          float: left;
          // border-right: dotted 1px #4c6193;
          // border-bottom: dotted 1px #4c6193;
          // overflow: hidden;
          .img1 {
            width: 3.375rem;
            height: 3.5rem;
            margin-left: 3.042rem;
          }
          .img11 {
            // width: 95px;
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            // text-align: center;
            margin-left: 2.875rem;
            margin-top: 1.5rem;
          }
          .img111 {
            display: block;
            width: 0.042rem;
            height: 6.333rem;
            border: dotted 0.042rem #4e6296;
            margin-left: 8.583rem;
            margin-top: -6.5rem;
          }
          .img1111 {
            display: block;
            width: 6.333rem;
            height: 0.042rem;
            border: dotted 1px #4e6296;
            margin-left: 1.25rem;
            margin-top: 1.25rem;
          }
          .img1112 {
            display: block;
            width: 6.333rem;
            height: 0.042rem;
            border: dotted 0.042rem #4e6296;
            margin-left: 1.25rem;
            margin-top: 1.417rem;
          }
          .img1113 {
            display: block;
            width: 6.333rem;
            height: 0.042rem;
            border: dotted 0.042rem #4e6296;
            margin-left: 1.25rem;
            margin-top: 1.417rem;
          }
          .img2 {
            width: 2.25rem;
            height: 3.292rem;
            margin-left: 3.042rem;
          }
          .img22 {
            // width: 70px;
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 2.875rem;
            margin-top: 1.583rem;
          }
          .img3 {
            width: 3.292rem;
            height: 3.375rem;
            margin-left: 2.917rem;
          }
          .img33 {
            // width: 120px;
            // height: 20px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            line-height: 1.625rem;
            // letter-spacing: 4px;
            color: #ffffff;
            margin-left: 2.083rem;
            margin-top: 1.25rem;
          }
          .img4 {
            width: 3.375rem;
            height: 3.333rem;
            margin-left: 3.083rem;
          }
          .img44 {
            // width: 69px;
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 3.042rem;
            margin-top: 1.583rem;
          }
          .img5 {
            width: 3.458rem;
            height: 2.917rem;
            margin-left: 2.708rem;
          }
          .img55 {
            // width: 94px;
            // height: 20px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 2.25rem;
            margin-top: 2rem;
          }
          .img6 {
            width: 3.25rem;
            height: 3.208rem;
            margin-top: 2.042rem;
            margin-left: 3.042rem;
          }
          .img66 {
            // width: 95px;
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 2.667rem;
            margin-top: 1.083rem;
          }
          .img7 {
            width: 3.042rem;
            height: 2.917rem;
            margin-top: 2.333rem;
            margin-left: 2.708rem;
          }
          .img77 {
            // width: 119px;
            // height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 1.75rem;
            margin-top: 1.083rem;
          }
          .img8 {
            width: 2.625rem;
            height: 3.542rem;
            margin-top: 2.083rem;
            margin-left: 3.167rem;
          }
          .img88 {
            // width: 95px;
            // height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-top: 0.75rem;
            margin-left: 2.5rem;
          }
          .img9 {
            width: 3.083rem;
            height: 3.167rem;
            margin-top: 2.208rem;
            margin-left: 3.042rem;
          }
          .img99 {
            // width: 93px;
            // height: 20px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-top: 1rem;
            margin-left: 2.625rem;
          }
          .img10 {
            width: 3.417rem;
            height: 3.25rem;
            margin-left: 2.5rem;
            margin-top: 2.083rem;
          }
          .img1010 {
            // width: 94px;
            // height: 20px;
            font-family: MicrosoftYaHei;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 39px;
            letter-spacing: 0.167rem;
            color: #ffffff;
            margin-left: 2.25rem;
            margin-top: 1.042rem;
          }
        }
        // li:after {
        //   content: "";
        //   position: absolute;
        //   bottom: -15px;
        //   top: auto;
        //   left: 40px;
        //   height: 3px;
        //   width: 10px;
        //   background-color: #e4262d;
        // }
      }
    }
  }
}
</style >

<style>
.invitetab .el-tabs__item {
  font-family: MicrosoftYaHei;
  font-size: 0.833rem;
  font-weight: normal;
  font-stretch: normal;
  width: 4.042rem;
  height: 1.333rem;
  line-height: 1.333rem;
  text-align: center !important;
  color: #ffffff;
}
.el-tabs__item:hover {
  /*  color: #374570; */

  background-color: #374570;
}

.invitetab .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 18px;
}
.invitetab .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 16px;
}
.invitetab .el-tabs__active-bar {
  height: 0px;
}
.invitetab .el-tabs__nav {
  position: relative;
  bottom: 0.083rem !important;
}

.invitetab .el-icon-caret-bottom:before {
  content: "\e790";
  font-size: 1.125rem;
  color: #556cb2;
  margin-top: -0.417rem;
}

.invitetab .el-icon-caret-top:before {
  content: "\e78f";
  font-size: 1.125rem;
  color: #556cb2;
}
.invitetab .el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
.invitetab .el-table .cell {
  width: 8.125rem;
}
.invitetab .el-table tr {
  background-color: transparent !important;
}
.invitetab .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}
.invitetab .el-table th.el-table__cell.is-leaf {
  background-color: transparent;
}
.invitetab .el-pagination.is-background .btn-prev {
  background-color: #1d2537;
}
.invitetab .el-pagination.is-background .btn-next {
  background-color: #1d2537;
}
.invitetab .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1d2537;
  color: #fff;
}
.invitetab .el-button {
  background-color: #556cb2;
  border-radius: 0.75rem;
  color: #fff;
}
.invitetab .el-table__row:hover > td {
  background-color: #ffffff !important;
}

.invitetab .el-table__row--striped:hover > td {
  background-color: #fafafa !important;
}
.el-dialog,
.el-pager li {
  background-color: #1f2235 !important;
}
.invitetab .el-dialog {
  background-color: #1f2235 !important;
}
.invitetab .el-dialog .el-button--primary {
  background-color: #556cb2 !important;
}

.invitetab .el-table__row:hover > td {
  background-color: #1f2235 !important;
}

.invitetab .el-table__row--striped:hover > td {
  background-color: #1f2235 !important;
}
</style>
