import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 关于麦迪科
    navContent: [
      {
        id: 1,
        name: '公司简介'
      },
      {
        id: 2,
        name: '业务范围'
      },
      {
        id: 3,
        name: '公司文化'
      },
      {
        id: 4,
        name: '团队风采'
      }
    ],
    // 业务服务
    service: [
      {
        id: 5,
        name: '过往业绩'
      },
      {
        id: 6,
        name: '服务模式'
      },
      {
        id: 7,
        name: '行业案例'
      },
      {
        id: 8,
        name: '公司荣誉'
      }

    ],
    // 招聘信息
    invite: [
      {
        id: 9,
        name: '招聘信息'
      },
      {
        id: 10,
        name: '公司福利'
      }
    ],
    titleLink: [
      // 首页
      {
        link: '/',
        children: [

        ],
        text: '首&nbsp;&nbsp;页'
      },
      // 关于麦迪克
      {
        link: '/instroduce',
        children: [
          {
            label: '公司简介',
            id: 'comProfile'
          },
          {
            label: '业务范围',
            id: 'scopeofService'
          },

          {
            label: '公司文化',
            id: 'corCulture'
          },
          {
            label: '团队风采',
            id: 'teamSpirit'
          }
        ],
        text: '关于麦迪克'
      },
      // 业务服务
      {
        link: '/service',
        children: [
          {
            label: '过往业绩',
            id: 'performance'
          },
          {
            label: '服务模式',
            id: 'processmode'
          },
          {
            label: '行业案例',
            id: 'businesscase'
          },
          {
            label: '公司荣誉',
            id: 'businesshonor'
          }
        ],
        text: '业务服务'
      },
      // 招聘信息
      {
        link: '/invite',
        children: [
          {
            label: '招聘信息',
            id: 'recruitmentInformation'
          },
          {
            label: '公司福利',
            id: 'beneJump'
          }

        ],
        text: '招贤纳士'
      },
      // 联系我们
      {
        link: '/contact',
        children: [

        ],
        text: '联系我们'
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
