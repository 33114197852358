import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout/index.vue'
import Home from '@/views/Home/index.vue'
// import Instroduce from '@/views/instroduce/index.vue' // 介绍
// import Instroduce from '@/views/instroduce/Iindex.vue' // 介绍
import Instroduce from '@/views/instroduce/index.vue' // 介绍
import Service from '@/views/Service/index.vue' // 业务服务
import Invite from '@/views/Invite/index.vue' // 招贤纳士
import Contact from '@/views/Contact/indexs.vue'
// 解决重复跳转路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  // 公共布局
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/', // 此时默认加载(我们进来什么都不输入的时候)的时候显示这个这个路由的内容
        component: Home // 这里我们component属性的值是我们导入路由组件的时候,起的变量名
      },
      {
        path: '/instroduce',
        component: Instroduce
      },
      {
        path: '/service',
        component: Service
      },
      {
        path: '/invite',
        component: Invite
      },
      {
        path: '/contact',
        component: Contact
      }

    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
