<template>
  <!-- 首页 -->
  <div class="big">
    <div class="home" id="homes" v-cloak>
      <!-- <Header @jumpId="getjump" /> -->
      <div class="title" v-cloak>
        <h1></h1>
      </div>
      <div class="test" v-cloak>
        <span>北京麦迪克未来科技有限公司 </span> <br />
        <span class="speciarity">
          深耕于字节跳动营销服务，与平台、合作伙伴携手成长，共赢未来！</span
        >
      </div>
    </div>
  </div>
</template>

<script>
// import Header from '../Layout/components/header.vue'

export default {
  components: {
    // Header

  },

  beforeRouteLeave (to, form, next) {
    window.pageYOffset = 0
    next()
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
  },
  data () {
    return {

    }
  },
  created () {
    this.loading = this.$loading({
      lock: true,
      fullscreen: true,
      text: 'Loading...'
    })
    this.$nextTick(() => {
      this.getlocal()
      this.loading.close()
    })
  },
  methods: {

    getlocal () {
      // 找到锚点id
      const selectId = localStorage.getItem('toId')
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    },
    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    }
  }
}

</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}
.home {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/home/home.png") no-repeat 0 0 / contain;
  overflow: hidden;

  .title {
    h1 {
      width: 36.5rem;
      height: 7.125rem;
      // font-family: SourceHanSansSC-Regular;
      // font-size: 3rem;
      // font-weight: normal;
      // font-stretch: normal;
      // color: #ffffff;
      // padding-top: 11.792rem;
      // text-align: center;
      // letter-spacing: 0.292rem;
      margin-top: 11.792rem;
      margin-left: 22rem;
      background: url("../../assets/home/20211104-113519.png") no-repeat 0 0 /
        contain;
    }
  }
  .test {
    height: 3rem;
    font-family: SourceHanSansSC-Light;
    font-size: 0.917rem;
    font-weight: normal;
    font-stretch: normal;
    margin-top: 2.417rem;
    color: #ccd7ff;
    line-height: 1.958rem;
    text-align: center;
  }
}
</style>
