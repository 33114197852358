import Vue from 'vue'
import App from './App.vue'
// import App from '@/views/Service/轮播区域.vue'
// import App from './轮播图.vue'
// import App from './轮播22.vue'
// import App from './锚点定位.vue'
import Resize from '@/untils/index.js'

import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

/*

*/
// import 'amfe-flexible'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// import 'normalize.css'
import './styles/common.less'
// 导入自己写的全局公共样式:
// import '@/styles/common.css'
// import 'lib-flexible'
// import './util/rem'
// import echarts from 'echarts'
// import * as echarts from 'echarts'
import server from './untils/api'
Vue.use(Resize)

Vue.prototype.server = server // 引入接口服务

Vue.use(ElementUI)
// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// Vue.use(ElementUI)
/*
"postcss": {
    "plugins": {
      "autoprefixer": {},
      "postcss-px2rem": {
        "remUnit": 192
      }
    }
  }
 */

new Vue({

  router,
  store,
  render: h => h(App)
}).$mount('#app')
