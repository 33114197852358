<template>
  <!-- 这里是业务服务页面 -->
  <div v-cloak>
    <!-- <Header @jumpId="getjump" /> -->
    <!-- 过往业绩 -->
    <div class="performance" id="performance">
      <div class="performanceTitle">
        <div class="performanceTitletop">过往业绩</div>
        <span class="performanceTitlefoot">Past performance</span>
      </div>
      <div class="performancetext">
        <div>躬行深耕，跃上葱茏。</div>
        <div>7年专注且专业，和平台、客户共生长！</div>
      </div>
      <!-- <div id="echarts"></div> -->

      <div class="echarts">
        <div class="div1">
          <div class="mask">
            <div class="div2"></div>
          </div>
        </div>
        <div class="div3">
          <div class="mask">
            <div class="div4"></div>
          </div>
        </div>
        <div class="div5">
          <div class="mask">
            <div class="div6"></div>
          </div>
        </div>
        <div class="div7">
          <div class="mask">
            <div class="div8"></div>
          </div>
        </div>
        <div class="div9">
          <div class="mask">
            <div class="div10"></div>
          </div>
        </div>
        <div class="div11">
          <div class="mask">
            <div class="div12"></div>
          </div>
        </div>
        <div class="div13">
          <div class="mask">
            <div class="div14"></div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- 下划线 -->
      <div class="performanceLine"></div>
      <!-- 小圆圈 -->

      <div class="performancecircle"></div>
      <div class="performancecircle1"></div>
      <div class="performancecircle2"></div>
      <div class="performancecircle3"></div>
      <div class="performancecircle4"></div>
      <div class="performancecircle5"></div>
      <div class="performancecircle6"></div>
      <div class="someyear">
        <!-- <div class="performanceLine"></div> -->
        <span class="oneyear">2014年</span>
        <span class="twoyear">2015年</span>
        <span class="threeyear">2016年</span>
        <span class="fouryear">2017年</span>
        <span class="fiveyear">2018年</span>
        <span class="sixyear">2019年</span>
        <span class="sevenyear">2020年</span>
      </div>
    </div>
    <!-- 服务模式 -->
    <div class="serviceMode" id="processmode">
      <div class="serviceModeTitle">
        <div class="serviceModeTitletop">
          <span class="serviceModeTitletop1">服务模式</span> &nbsp;
          <span class="serviceModeTitletop2">一站式数字营销托管服务</span>
        </div>
        <div class="serviceModeTitlefoot">Service mode</div>
      </div>
      <!-- 图片 -->
      <div class="serviceModepicture">
        <div class="serviceModepicturetitle">服务流程</div>
        <div class="circle1test">运营策略</div>
        <!-- <div class="circle1test1">Operational strategy</div> -->
        <div class="circle1test1angle"></div>
        <div class="circle2test">物料准备</div>
        <!-- <div class="circle2test2">Material preparation</div> -->
        <div class="circle2test2angle"></div>
        <div class="circle3test">线上运营</div>
        <!-- <div class="circle3test3">Online operation</div> -->
        <div class="circle3test3angle"></div>
        <div class="circle4test">运营诊断</div>
        <!-- <div class="circle4test4">Operational diagnosis</div> -->
        <div class="circle4test4angle"></div>
        <div class="circle5test">数据复盘</div>
        <!-- <div class="circle5test5">Data replication</div> -->
        <div class="circle5test5angle"></div>
      </div>
      <div class="serviceModeTitle2">服务保障</div>
      <div class="serviceModeTitle2undline"></div>
      <div class="serviceModeTitle3text">
        <span>商务服务</span>
        <span>优化运营</span>
        <span>物料生产</span>
        <span>后端赋能</span>
      </div>
      <div class="serviceModeTitle3text2">
        <div class="serviceModeTitle3text21">
          10年以上从业团队
          <br />
          高效统筹推进项目
        </div>
        <div class="serviceModeTitle3text22">
          早期字节系运营团队<br />
          务实能拼，行业翘楚
        </div>
        <div class="serviceModeTitle3text23">
          视频/设计自设团队<br />
          实战练兵，资源丰富<br />
        </div>

        <div class="serviceModeTitle3text24">
          技术<br />
          提升效率、维护安全<br />
          &nbsp;
        </div>
      </div>
    </div>
    <!-- 行业案例及客户 -->
    <div class="caseCustomer" id="businesscase">
      <div class="caseCustomerTitle">
        <div class="caseCustomerTitletop">
          <span class="caseCustomerTitletop1"> 行业案例及客户 </span>
          <span class="caseCustomerTitletop2"
            >教育、金融 、社交、工具、电商</span
          >
        </div>
        <div class="caseCustomerTitlefoot">Industry cases and customers</div>
      </div>
      <div class="caseCustomercontent">
        <div class="caseCustomercontent1">
          <div class="caseCustomercontent1img"></div>
          <div class="caseCustomercontent1imgtext">教 育</div>
          <div class="caseCustomercontent1imgtextundline"></div>
          <div class="caseCustomercontent1content">
            6年专注教育,共生长 <br />
            经历字节教育从0到N的全历程<br />
          </div>
        </div>
        <div class="caseCustomercontent2">
          <div class="caseCustomercontent2img"></div>
          <div class="caseCustomercontent2imgtext">金 融</div>
          <div class="caseCustomercontent2imgtextundline"></div>
          <div class="caseCustomercontent2content">
            多年积累快速破圈,服务行业内<br />
            多家TOP客户稳定放量<br />
          </div>
        </div>
        <div class="caseCustomercontent3">
          <div class="caseCustomercontent3img"></div>
          <div class="caseCustomercontent3imgtext">社交|工具|网服|快消</div>
          <div class="caseCustomercontent3imgtextundline"></div>
          <div class="caseCustomercontent3content">
            增长新空间，探索业务新模式<br />
            破茧新生值得期待
          </div>
        </div>
      </div>
      <!-- 小型轮播图 -->
      <div
        class="caseCustomerpicture"
        v-on:mouseover="stopmove()"
        v-on:mouseout="move()"
      >
        <ul :style="{ left: calleft + 'px' }" class="imgBoxoul">
          <li
            v-for="(item, index) in dataList"
            :key="index"
            @click="gotodetails(item.id)"
            ref="lis"
          >
            <img :src="item.img" />
          </li>
        </ul>
      </div>
    </div>
    <!--  公司荣誉-->
    <div class="companyHonor" id="businesshonor">
      <div class="companyHonorTitle">
        <div class="companyHonorTitletop">公司荣誉</div>
        <span class="companyHonorTitlefoot">Company honor</span>
      </div>
      <div class="Carousel">
        <!-- interval 自动切换的时间 heiht:走马灯的高度  -->
        <el-carousel
          :interval="5000"
          indicator-position="outside"
          arrow="never"
          ref="cardShow"
        >
          <el-carousel-item v-for="(item, index) in honnerList" :key="index">
            <!-- 轮播的内容 -->
            <div class="Carousel-content">
              <div v-for="(arr, z) in item" :key="z">
                <img :src="arr.img" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- 左滑图标 -->
      </div>

      <!-- 左滑图标 -->
      <div class="iconleft" @click="arrowClick('left')">
        <!-- src="../../assets/业务服务/04公司荣誉/左键.png" -->
        <img
          src="../../assets/业务服务/04公司荣誉/左键.png"
          class="iconleft1"
          alt=""
        />
      </div>
      <!-- 右滑 -->
      <div class="iconright" @click="arrowClick('right')">
        <img
          class="iconright1"
          src="../../assets/业务服务/04公司荣誉/右键.png"
          alt=""
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import Header from '../Layout/components/header.vue'
import Footer from '../Layout/components/footer.vue'

export default {
  components: {
    // Header,
    Footer
  },

  data () {
    return {
      calleft: 0,
      callefts: 0, // 公司荣誉轮播图
      dataList: [{
        id: 1,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/01新东方.png')
      },

      {
        id: 2,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/02众安小贷.png')
      },
      {
        id: 3,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/03开课吧.png')
      },
      {
        id: 4,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/04水滴保.png')
      },

      {
        id: 5,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/05爱聊.png')
      },
      {
        id: 6,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/06还呗.png')
      },
      {
        id: 7,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/07嗨学.png')
      },
      {
        id: 8,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/08新航道.png')
      },
      {
        id: 9,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/09学天教育.png')
      },
      {
        id: 10,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/10qq阅读.png')
      },
      {
        id: 11,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/11有师.png')
      },
      {
        id: 12,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/12职上.png')
      },
      {
        id: 13,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/13光合青春.png')
      },
      {
        id: 14,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/14点众.png')
      },
      {
        id: 15,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/15友缘在线.png')
      },
      {
        id: 16,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/16花椒直播.png')
      },
      {
        id: 17,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/17桔子树.png')
      },
      {
        id: 18,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/18星火保.png')
      }, {
        id: 19,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/19猿辅导.png')
      },
      {
        id: 20,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/20萤火保.png')
      },
      {
        id: 21,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/21优路.png')
      },
      {
        id: 22,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/22启牛.png')
      },
      {
        id: 23,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/23学而思.png')
      },
      {
        id: 24,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/24火花思维.png')
      }, {
        id: 25,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/25华图.png')
      },
      {
        id: 26,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/26紫荆教育.png')
      },
      {
        id: 27,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/27中欧.png')
      },
      {
        id: 28,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/28十方教育.png')
      },
      {
        id: 29,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/29环球网校.png')
      },
      {
        id: 30,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/30米读.png')
      },
      {
        id: 31,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/31对啊.png')
      },
      {
        id: 32,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/32赛博纳斯.png')
      },
      {
        id: 33,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/33赛普健身.png')
      },
      {
        id: 34,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/34学慧网.png')
      },
      {
        id: 35,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/35黑牛.png')
      },
      {
        id: 36,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/36微淼.png')
      },
      {
        id: 37,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/37作业帮.png')
      },
      {
        id: 38,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/38高途.png')
      },
      {
        id: 39,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/39-51talk.png')
      },
      {
        id: 40,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/40叫叫.png')
      },
      {
        id: 1,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/01新东方.png')
      },

      {
        id: 2,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/02众安小贷.png')
      },
      {
        id: 3,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/03开课吧.png')
      },
      {
        id: 4,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/04水滴保.png')
      },

      {
        id: 5,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/05爱聊.png')
      },
      {
        id: 6,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/06还呗.png')
      },
      {
        id: 7,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/07嗨学.png')
      },
      {
        id: 8,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/08新航道.png')
      },
      {
        id: 9,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/09学天教育.png')
      },
      {
        id: 10,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/10qq阅读.png')
      },
      {
        id: 11,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/11有师.png')
      },
      {
        id: 12,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/12职上.png')
      },
      {
        id: 13,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/13光合青春.png')
      },
      {
        id: 14,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/14点众.png')
      },
      {
        id: 15,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/15友缘在线.png')
      },
      {
        id: 16,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/16花椒直播.png')
      },
      {
        id: 17,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/17桔子树.png')
      },
      {
        id: 18,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/18星火保.png')
      }, {
        id: 19,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/19猿辅导.png')
      },
      {
        id: 20,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/20萤火保.png')
      },
      {
        id: 21,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/21优路.png')
      },
      {
        id: 22,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/22启牛.png')
      },
      {
        id: 23,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/23学而思.png')
      },
      {
        id: 24,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/24火花思维.png')
      }, {
        id: 25,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/25华图.png')
      },
      {
        id: 26,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/26紫荆教育.png')
      },
      {
        id: 27,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/27中欧.png')
      },
      {
        id: 28,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/28十方教育.png')
      },
      {
        id: 29,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/29环球网校.png')
      },
      {
        id: 30,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/30米读.png')
      },
      {
        id: 31,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/31对啊.png')
      },
      {
        id: 32,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/32赛博纳斯.png')
      },
      {
        id: 33,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/33赛普健身.png')
      },
      {
        id: 34,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/34学慧网.png')
      },
      {
        id: 35,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/35黑牛.png')
      },
      {
        id: 36,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/36微淼.png')
      },
      {
        id: 37,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/37作业帮.png')
      },
      {
        id: 38,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/38高途.png')
      },
      {
        id: 39,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/39-51talk.png')
      },
      {
        id: 40,
        url: '',
        img: require('../../assets/业务服务/03行业案例/logo/40叫叫.png')
      }

      ],
      honnerList: [
        // 第一个数组
        [
          // 第1个数组

          {
            id: 13,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/21/21-1.png')
          },
          {
            id: 14,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/21/21-2.png')
          },
          {
            id: 110,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/21/21-3.png')
          },
          {
            id: 111,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/21/21-4.png')
          }

        ],
        // 第2个数组

        [
          {
            id: 9,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/20/20-1.png')
          },
          {
            id: 10,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/20/20-2.png')
          },
          {
            id: 11,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/20/20-3.png')
          },
          {
            id: 12,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/20/20-4.png')
          }

        ],
        // 第3个数组
        [

          {
            id: 5,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/19/19-1.png')
          },
          {
            id: 6,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/19/19-2.png')
          },
          {
            id: 7,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/19/19-3.png')
          },
          {
            id: 8,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/19/19-4.png')
          }

        ],
        // 第4个数组
        [
          {
            id: 1,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/17-18/1718-01.png')
          },
          {
            id: 2,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/17-18/1718-02.png')
          },
          {
            id: 3,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/17-18/1718-03.png')
          },
          {
            id: 4,
            url: '',
            img: require('../../assets/业务服务/04公司荣誉/17-18/1718-04.png')
          }

        ]
      ]
      // 第2个数组
    }
  },

  created () {
    this.move()
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)// 监听页面滚动事件
  },
  mounted () {

  },
  // created () {
  //   // this.move()
  //   // this.moveHonor()
  // },
  methods: {

    getjump (id) {
      if (document.querySelector(`#${id}`) !== '' && document.querySelector(`#${id}`) != null) {
        document.querySelector(`#${id}`).scrollIntoView()
      }
    },
    getlocal () {
      // 找到锚点id
      const selectId = localStorage.getItem('toId')
      const toElement = document.getElementById(selectId)
      // 如果对应id存在，就跳转
      if (selectId !== '' && selectId !== 0 && toElement != null) {
        toElement.scrollIntoView()
        localStorage.setItem('toId', '')
      }
    },
    // 自动移动
    // move () {
    //   this.timer = setInterval(this.starmove, 2000)
    // },
    // 开始移动
    // starmove () {
    //   this.calleft -= 240

    //   if (this.calleft < -720) {
    //     this.calleft = 0
    //   }
    // },

    // 鼠标悬停时停止移动
    // stopmove () {
    //   clearInterval(this.timer)
    // },
    // 点击按钮左移
    // zuohua () {
    //   this.calleft -= 240
    //   if (this.calleft < -720) {
    //     this.calleft = 0
    //   }
    // },
    // 点击按钮右移
    // youhua () {
    //   this.calleft += 240
    //   if (this.calleft > 0) {
    //     this.calleft = -720
    //   }
    // }

    // 小图标轮播图移动
    move () {
      this.timer = setInterval(this.starmove, 20)
    },
    // 小图标开始移动
    starmove () {
      this.calleft -= 1.2//* this.speed
      if (this.calleft <= -3720) {
        this.calleft = 0
      }
    },
    // 小图标鼠标悬停时停止移动
    stopmove () {
      clearInterval(this.timer)
    },
    // 荣耀图移动
    moveHonor () {
      this.timer2 = setInterval(this.starmoveHonor, 20)
    },
    // 开始移动
    starmoveHonor () {
      this.callefts -= 1//* this.speed
      if (this.callefts <= -3720) {
        this.callefts = 0
      }
    },

    // 停止移动
    notMove () {
      clearInterval(this.timer2)
    },
    // 大图标点击按钮左移
    zuohua () {
      this.callefts -= 167// this.speed = 1
      if (this.callefts <= -3668) {
        this.callefts = 0
      }
    },
    // 大图点击按钮右移
    youhua () { // this.speed = -1
      this.callefts += 167
      if (this.callefts >= 0) {
        this.callefts = -3668
      }
    },
    arrowClick (val) {
      if (val === 'right') {
        console.log('右滑', val)
        this.$refs.cardShow.next()
      } else {
        console.log('左滑', val)
        this.$refs.cardShow.prev()
      }
    }
  }
}
</script>

<style scoped lang="less">
.performance {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/业务服务/01过往业绩/01过往业绩-底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  .performanceTitle {
    // width: 10.083rem;
    height: 3.333rem;
    // background-color: red;
    margin-top: 10.083rem;
    margin-left: 17.417rem;
    border-left: 0.375rem solid white;
    .performanceTitletop {
      height: 1.583rem;
      font-family: SourceHanSansSC-Medium;
      font-size: 1.583rem;
      font-weight: normal;
      font-stretch: normal;
      padding-left: 1.083rem;
      letter-spacing: 0.083rem;
      color: #ffffff;
      margin-bottom: 0.625rem;
    }
    .performanceTitlefoot {
      // height: 21px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      padding-left: 1.083rem;
      letter-spacing: 0.125rem;
      color: #9fafdf;
    }
  }
  .performancetext {
    margin-left: 18.875rem;
    margin-top: 2.167rem;
    color: #e5e5e5;
    div {
      margin-bottom: 0.417rem;
      letter-spacing: 0.042rem;
    }
  }
  .echarts {
    margin-left: 19.833rem;
    margin-top: -50px;
    width: 40.458rem;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    // width: 40.458rem;
    // height: 25rem;
    // margin: 0 auto;
    // background-color: green;
    // margin-top: 20px;
    .div1 {
      width: 2.917rem;
      height: 1.375rem;

      position: relative;
      // top: 22rem;
      // margin-top: 439px;
      // left: 19.833rem;
      // top: 18.292rem;
      .mask {
        width: 2.917rem;
        height: 1.375rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div2 {
          width: 2.917rem;
          height: 1.375rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .mask {
        animation: animate 10s ease forwards;
        overflow: hidden;
      }
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 1.375rem;
        }
        /* to是最终状态 */
        to {
          height: 1.375rem;
          top: 0px;
        }
      }
    }
    .div3 {
      width: 2.917rem;
      height: 2.208rem;
      // background: #ccc;
      position: relative;
      // left: 26.083rem;
      // top: 17rem;
      .mask {
        width: 2.917rem;
        height: 2.208rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div4 {
          width: 2.917rem;
          height: 2.208rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .mask {
        animation: animate 5s ease forwards;
        overflow: hidden;
      }
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 2.208rem;
        }
        /* to是最终状态 */
        to {
          height: 2.208rem;
          top: 0px;
        }
      }
    }
    .div5 {
      width: 2.917rem;
      height: 2.708rem;
      // background: #ccc;
      position: relative;
      .mask {
        width: 2.917rem;
        height: 2.708rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div6 {
          width: 2.917rem;
          height: 2.708rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      /* 给遮罩增加动画 */
      .mask {
        animation: animate 5s ease forwards;
        overflow: hidden;
      }
      /* 设置动画名字 */
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 2.708rem;
        }
        /* to是最终状态 */
        to {
          height: 2.708rem;
          top: 0px;
        }
      }
    }
    .div7 {
      width: 2.917rem;
      height: 4rem;
      // background: #ccc;
      position: relative;
      .mask {
        width: 2.917rem;
        height: 4rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div8 {
          width: 2.917rem;
          height: 4rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      .mask {
        animation: animate 5s ease forwards;
        overflow: hidden;
      }
      /* 设置动画名字 */
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 4rem;
        }
        /* to是最终状态 */
        to {
          height: 4rem;
          top: 0px;
        }
      }
    }
    .div9 {
      width: 2.917rem;
      height: 4.417rem;
      /* background: #ccc; */
      position: relative;
      /*mask就是个遮罩,必须要有,如果没有就不会从下向上移动,他和我们要移动的那个盒子的宽高一样 遮罩在那个位置 盒子就在那个位置移动  必须是一个遮罩对应一个高度*/
      .mask {
        width: 2.917rem;
        height: 4.417rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div10 {
          width: 2.917rem;
          height: 4.417rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      /* 给遮罩增加动画 */
      .mask {
        animation: animate 3s ease forwards;
        overflow: hidden;
      }
      /* 设置动画名字 */
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 4.417rem;
        }
        /* to是最终状态 */
        to {
          height: 4.417rem;
          top: 0px;
        }
      }
    }
    .div11 {
      width: 2.917rem;
      height: 8.792rem;
      /* background: #ccc; */
      position: relative;
      .mask {
        width: 2.917rem;
        height: 8.792rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div12 {
          width: 2.917rem;
          height: 8.792rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      /* 给遮罩增加动画 */
      .mask {
        animation: animate 3s ease forwards;
        overflow: hidden;
      }
      /* 设置动画名字 */
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 8.792rem;
        }
        /* to是最终状态 */
        to {
          height: 8.792rem;
          top: 0px;
        }
      }
    }
    .div13 {
      width: 2.917rem;
      height: 22.083rem;
      /* background: #ccc; */
      position: relative;
      .mask {
        width: 2.917rem;
        height: 22.083rem; /* 高度是变量 */
        position: absolute;
        left: 0;
        top: 0; /* top是变量 */
        .div14 {
          width: 2.917rem;
          height: 22.083rem;
          background: #6073c9;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      /* 给遮罩增加动画 */
      .mask {
        animation: animate 1s ease forwards;
        overflow: hidden;
      }
      /* 设置动画名字 */
      @keyframes animate {
        /* fron 是初始状态 */
        from {
          height: 0px;
          top: 22.083rem;
        }
        /* to是最终状态 */
        to {
          height: 22.083rem;
          top: 0px;
        }
      }
    }
  }
  .performanceLine {
    width: 61.042rem;
    height: 0.167rem;
    margin-left: 9.5rem;
    background: url("../../assets/业务服务/01过往业绩/01过往业绩-线.png")
      no-repeat 0 0 / contain;
    margin-top: 0.833rem;
    // background-color: #5f6cc7;
  }
  .performancecircle {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 22.5rem !important;
    margin-top: -0.333rem;
  }
  .performancecircle1 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 28.333rem !important;
    margin-top: -0.417rem;
  }
  .performancecircle2 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 34rem !important;
    margin-top: -0.417rem;
  }
  .performancecircle3 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 39.833rem !important;
    margin-top: -0.417rem;
  }
  .performancecircle4 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 45.6rem !important;
    margin-top: -0.417rem;
  }
  .performancecircle5 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 51.375rem !important;
    margin-top: -0.417rem;
  }
  .performancecircle6 {
    width: 0.417rem;
    height: 0.417rem;
    border-radius: 50%;
    background-color: #5f6cc7;
    margin-left: 57.225rem !important;
    margin-top: -0.417rem;
  }
  .someyear {
    width: 40.458rem;
    height: 0.667rem;
    // background-color: red;
    margin: 0.417rem auto 0 auto;
    position: relative;

    span {
      font-family: "OPPOSans-L";
      font-size: 0.583rem;
      line-height: 0.667rem;
      letter-spacing: 0.042rem;
      color: #ffffff;
    }
    .oneyear {
      // margin-left: 21.3rem;
      // margin-top: -0.375rem;
      position: absolute;
      left: 1.6rem;
      top: 0;
    }
    .twoyear {
      position: absolute;

      left: 7.4rem;
    }
    .threeyear {
      position: absolute;

      left: 13rem;
    }
    .fouryear {
      position: absolute;
      left: 19rem;
    }
    .fiveyear {
      position: absolute;
      left: 24.7rem;
    }
    .sixyear {
      position: absolute;
      left: 30.5rem;
    }
    .sevenyear {
      position: absolute;
      left: 36.2rem;
    }
  }
}
.serviceMode {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/业务服务/02服务模式/02服务模式-底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  .serviceModeTitle {
    // width: 22.708rem;
    height: 3.333rem;
    // background-color: red;
    margin-left: 17.417rem;
    margin-top: 5.458rem;
    border-left: 0.375rem solid white;
    .serviceModeTitletop {
      margin-left: 1.042rem;
      .serviceModeTitletop1 {
        // width: 165px;
        // height: 38px;
        font-family: SourceHanSansSC-Medium;
        font-size: 1.583rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
      }
      .serviceModeTitletop2 {
        // width: 323px;
        // height: 27px;
        font-family: SourceHanSansSC-Light;
        font-size: 1.083rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
      }
    }
    .serviceModeTitlefoot {
      // width: 157px;
      // height: 16px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.125rem;

      color: #9fafdf;
      margin-left: 1.042rem;
    }
  }
  .serviceModepicture {
    width: 36.375rem;
    height: 18.75rem;
    background: url("../../assets/业务服务/02服务模式/6.png") no-repeat 0 0 /
      contain;
    margin-left: 22.333rem;
    overflow: hidden;

    .serviceModepicturetitle {
      // width: 298px;
      // height: 29px;
      font-family: SourceHanSansSC-Light;
      font-size: 1.25rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 2.5rem;
      color: #8c94ce;
      margin-left: 12.167rem;
      margin-top: 6.667rem;
    }
    .circle1test {
      // width: 120px;
      // height: 27px;
      font-family: SourceHanSansSC-Light;
      font-size: 1.167rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.125rem;
      color: #3a4490;
      margin-left: 2.5rem;
      margin-top: -0.417rem;
    }
    .circle1test1 {
      // width: 128px;
      // height: 10px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.417rem !important;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      // letter-spacing: 2px;
      color: #8a95ed;
      margin-left: 1.333rem;
      margin-top: 0.167rem;
    }
    .circle1test1angle {
      width: 1.292rem;
      height: 0.167rem;
      background-color: #9ba5e9;
      border: solid 0px #070002;
      margin-left: 4.375rem;
      margin-top: 0.417rem;
    }
    .circle2test {
      font-family: SourceHanSansSC-Light;
      font-size: 1.083rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.125rem;
      color: #232b69;
      margin-left: 16.667rem;
      margin-top: 1.917rem;
    }
    .circle2test2 {
      // width: 126px;
      // height: 10px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.417rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      // letter-spacing: 2px;
      color: #8e98f1;
      margin-left: 15.333rem;
      margin-top: 1.417rem;
    }
    .circle2test2angle {
      width: 1.292rem;
      height: 0.167rem;
      background-color: #9ba5e9;
      border: solid 0px #070002;
      margin-left: 18.333rem;
      margin-top: 0.617rem;
    }
    .circle3test {
      // width: 91px;
      // height: 20px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.917rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.042rem;
      color: #ffffff;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.59);
      margin-left: 30.583rem;
      margin-top: -6rem;
    }
    .circle3test3 {
      // width: 101px;
      // height: 10px;
      // font-family: SourceHanSansSC-Light;
      font-size: 10px !important;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      // letter-spacing: 2px;
      color: #bcc4f0;
      margin-left: 710px;
      margin-top: 6px;

      transform: scale(0.8, 0.8);
      *font-size: 10px;
    }
    .circle3test3angle {
      width: 1.292rem;
      height: 0.167rem;
      background-color: #bec6ff;
      border: solid 0px #070002;
      margin-left: 31.833rem;
      margin-top: 0.5rem;
    }
    .circle4test {
      // width: 83px;
      // height: 19px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.667rem;
      letter-spacing: 0.042rem;
      color: #ffffff;
      margin-left: 23.667rem;
      margin-top: -7.4rem;
    }
    .circle4test4 {
      // width: 102px;
      // height: 9px;
      font-family: SourceHanSansSC-Light;
      font-size: 4px;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 1px;
      color: #a3ace3;
      // font-size: 12px;
      transform: scale(0.5, 0.5);
      *font-size: 8px;
      margin-left: 450px;
      margin-top: 4px;
    }
    .circle4test4angle {
      width: 1.292rem;
      height: 0.167rem;
      background-color: #9ba5e9;
      border: solid 0px #070002;
      margin-left: 24.75rem;
      margin-top: 0.6rem;
    }
    .circle5test {
      // width: 76px;
      // height: 18px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0.667rem;
      letter-spacing: 0.042rem;
      color: #dee2ff;
      margin-left: 10.6rem;
      margin-top: -2.2rem;
      transform: scale(0.8, 0.8);
      // font-size: 8px;
    }
    .circle5test5 {
      // width: 79px;
      // height: 9px;
      font-family: SourceHanSansSC-Light;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 1px;
      color: #8b95d1;
      transform: scale(0.6, 0.6);
      margin-left: 154px;
      margin-top: -4px;
    }
    .circle5test5angle {
      width: 1.292rem;
      height: 0.167rem;
      background-color: #9ba5e9;
      border: solid 0px #070002;
      margin-left: 13.75rem;
      margin-top: 0.367rem;
    }
  }
  .serviceModeTitle2 {
    width: 5.833rem;
    // height: 29px;
    font-family: SourceHanSansSC-Regular;
    font-size: 1.25rem;
    font-weight: normal;
    font-stretch: normal;
    // line-height: 16px;
    letter-spacing: 0.167rem;
    color: #bec6ff;
    margin-left: 18.333rem;
    border-bottom: 0.208rem solid #bec6ff;
    padding-bottom: 0.5rem;
  }
  .serviceModeTitle2undline {
    width: 43.75rem;
    height: 0.042rem;
    background-color: #bec6ff;
    margin-left: 18.333rem;
    margin-top: -0.125rem;
  }
  .serviceModeTitle3text {
    width: 45.833rem;
    height: 1.917rem;
    // background-color: red;
    margin-left: 18.333rem;
    margin-top: 1.292rem;
    text-align: center;
    line-height: 1.917rem;
    span {
      display: inline-block;
      width: 9.042rem;
      height: 1.875rem;
      background-color: #252d57;
      border-radius: 0.958rem;
      // border: solid 0px #070002;

      margin-right: 2.333rem;
      color: #ffffff;

      font-family: SourceHanSansSC-Light;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.25rem;
    }
  }
  .serviceModeTitle3text2 {
    .serviceModeTitle3text21 {
      width: 7.5rem;
      height: 2.5rem;
      // background-color: red;
      margin-left: 19.5rem;
      margin-top: 0.833rem;

      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.25rem;
      letter-spacing: 0.042rem;
      color: #dee2ff;
    }
    .serviceModeTitle3text22 {
      width: 7.5rem;
      height: 2.5rem;
      // background-color: red;
      margin-left: 30.833rem;
      margin-top: -2.5rem;

      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.25rem;
      letter-spacing: 0.042rem;
      color: #dee2ff;
    }
    .serviceModeTitle3text23 {
      // width: 159px;
      height: 3.25rem;
      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.25rem;
      letter-spacing: 0.042rem;
      color: #dee2ff;
      margin-left: 42.5rem;
      margin-top: -2.5rem;
    }
    .serviceModeTitle3text24 {
      // width: 159px;
      height: 3.25rem;
      font-family: SourceHanSansSC-Light;
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.25rem;
      letter-spacing: 0.042rem;
      color: #dee2ff;
      margin-left: 54.167rem;
      margin-top: -3.333rem;
    }
  }
}
.caseCustomer {
  width: 80rem;
  height: 42.5rem;
  background: url("../../assets/业务服务/03行业案例/03行业案例-底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  .caseCustomerTitle {
    // width: 712px;
    height: 3.333rem;
    // background-color: red;
    border-left: 0.375rem solid white;
    margin-top: 4.75rem;

    margin-left: 17.417rem;

    .caseCustomerTitletop {
      .caseCustomerTitletop1 {
        width: 12rem;
        height: 1.583rem;
        font-family: SourceHanSansSC-Medium;
        font-size: 1.583rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.083rem;
        color: #ffffff;
        // background-color: purple;
        margin-left: 1.042rem;
      }
      .caseCustomerTitletop2 {
        // width: 366px;
        // height: 27px;
        font-family: SourceHanSansSC-Light;
        font-size: 1.083rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        // letter-spacing: 2px;
        color: #ffffff;
      }
    }
    .caseCustomerTitlefoot {
      // width: 352px;
      // height: 21px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;

      letter-spacing: 0.125rem;
      color: #9fafdf;
      margin-left: 1.042rem;
    }
  }
  .caseCustomercontent {
    width: 45.417rem;
    height: 18.125rem;
    // background-color: skyblue;
    margin-left: 17.417rem;
    margin-top: 2.458rem;
    .caseCustomercontent1 {
      // transition: all 0.9s;
      width: 14.583rem;
      height: 18.042rem;
      background-color: #171c2f;
      overflow: hidden;
      .caseCustomercontent1img {
        width: 3.583rem;
        height: 3.583rem;
        margin-left: 5.5rem;
        margin-top: 1.583rem;
        background: url("../../assets/业务服务/03行业案例/3.png") no-repeat 0 0 /
          contain;
      }
      .caseCustomercontent1imgtext {
        // width: 74px;
        // height: 29px;
        margin-left: 6rem;
        margin-top: 0.667rem;
        font-family: SourceHanSansSC-Light;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.208rem;
        color: #dee2ff;
      }
      .caseCustomercontent1imgtextundline {
        width: 10.917rem;
        height: 0.042rem;
        border: 0.042rem dashed #9ba5e9;
        margin-left: 1.708rem;
        margin-top: 0.833rem;
      }
      .caseCustomercontent1content {
        // width: 216px;
        // height: 105px;
        // background-color: yellow;

        font-family: SourceHanSansSC-ExtraLight;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.208rem;
        letter-spacing: 0.042rem;
        color: #dee2ff;
        margin-top: 1.208rem;
        margin-left: 1.8rem;
      }
    }
    .caseCustomercontent1:hover {
      //鼠标移入改背景颜色 盒子阴影 向上移动
      background-color: #272f4c;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
      transform: translateY(-5px);
    }
    .caseCustomercontent2 {
      width: 14.583rem;
      height: 18.042rem;
      background-color: #171c2f;
      margin-top: -18.042rem;
      margin-left: 15.375rem;
      overflow: hidden;
      .caseCustomercontent2img {
        width: 3.583rem;
        height: 3.583rem;
        margin-left: 5.5rem;
        margin-top: 1.583rem;
        background: url("../../assets/业务服务/03行业案例/4.png") no-repeat 0 0 /
          contain;
      }
      .caseCustomercontent2imgtext {
        // width: 76px;
        // height: 29px;
        font-family: SourceHanSansSC-Light;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.208rem;
        color: #dee2ff;
        margin-left: 6rem;
        margin-top: 0.667rem;
      }
      .caseCustomercontent2imgtextundline {
        width: 10.917rem;
        height: 0.042rem;
        border: 0.042rem dashed #9ba5e9;
        margin-left: 1.708rem;
        margin-top: 0.833rem;
      }
      .caseCustomercontent2content {
        font-family: SourceHanSansSC-ExtraLight;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.208rem;
        letter-spacing: 0.042rem;
        color: #dee2ff;
        margin-top: 1.208rem;
        margin-left: 1.8rem;
      }
    }
    .caseCustomercontent2:hover {
      background-color: #272f4c;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
      transform: translateY(-5px);
    }
    .caseCustomercontent3 {
      width: 14.583rem;
      height: 18.042rem;
      background-color: #171c2f;
      margin-top: -18.042rem;
      margin-left: 30.792rem;
      overflow: hidden;
      .caseCustomercontent3img {
        width: 3.583rem;
        height: 3.583rem;
        margin-left: 5.5rem;
        margin-top: 1.583rem;
        background: url("../../assets/业务服务/03行业案例/5.png") no-repeat 0 0 /
          contain;
      }
      .caseCustomercontent3imgtext {
        // width: 249px;
        // height: 21px;
        font-family: SourceHanSansSC-Light;
        font-size: 0.917rem;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 16px;
        letter-spacing: 0.25rem;
        color: #dee2ff;
        // border-right: 1px solid #53586f;
        margin-left: 2.083rem;
        // margin-top: 0.875rem;
        margin-top: 0.8rem;
      }
      .caseCustomercontent3imgtextundline {
        width: 10.917rem;
        height: 0.042rem;
        border: 0.042rem dashed #9ba5e9;
        margin-left: 1.708rem;
        margin-top: 0.72rem;
      }
      .caseCustomercontent3content {
        font-family: SourceHanSansSC-ExtraLight;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1.208rem;
        letter-spacing: 0.042rem;
        color: #dee2ff;
        margin-top: 1.208rem;
        margin-left: 1.8rem;
      }
    }
    .caseCustomercontent3:hover {
      background-color: #272f4c;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
      transform: translateY(-5px);
    }
  }
  // 小图轮播图
  .caseCustomerpicture {
    width: 48.958rem;
    height: 3.875rem;
    border: 0.042rem solid transparent !important;
    // background-color: red;
    margin-top: 3.833rem;
    margin-left: 15.75rem;
    position: relative;
    overflow-y: auto;

    overflow: hidden;

    transition: all 0.3s;
    ul {
      margin: 0 auto;
      width: 400rem;
      height: 3.875rem;
      position: absolute;
      left: 0px;
      cursor: pointer;
      display: flex;
      // left: 413px;
      overflow: hidden;
      z-index: 10;
      // background-color: green;
      li {
        width: 3.875rem;
        height: 3.875rem;
        margin-right: 1.125rem;
        // overflow: hidden;
        img {
          width: 3.875rem;
          height: 3.875rem;
        }
      }
    }
  }
}
.companyHonor {
  width: 80rem;
  height: 29.417rem;
  background: url("../../assets/业务服务/04公司荣誉/04公司荣誉-底图.png")
    no-repeat 0 0 / contain;
  overflow: hidden;
  background-color: red;
  position: relative;
  .companyHonorTitle {
    width: 9.583rem;
    height: 3.333rem;
    // background-color: red;
    margin-left: 17.417rem;
    margin-top: 2.375rem;
    border-left: 0.375rem solid white;
    .companyHonorTitletop {
      // width: 164px;
      // height: 38px;
      font-family: SourceHanSansSC-Medium;
      font-size: 1.667rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.083rem;
      color: #ffffff;
      margin-left: 1.083rem;
    }
    .companyHonorTitlefoot {
      // width: 185px;
      // height: 21px;
      font-family: SourceHanSansSC-Light;
      font-size: 0.833rem;
      font-weight: normal;
      font-stretch: normal;
      // line-height: 16px;
      letter-spacing: 0.125rem;
      color: #9fafdf;
      margin-left: 1.083rem;
    }
  }
  .Carousel {
    width: 43.333rem;
    height: 13.958rem;
    // height: 29.417rem;
    // background-color: red;
    margin-top: 2.667rem;
    margin-left: 18.17rem !important;

    // z-index: 100;
    // box-shadow: 0px 5px 11px rgb(199, 195, 195);

    /* border: 1px solid red; */
    position: relative;
    // background-color: aqua;
    .Carousel-content {
      display: flex;

      /* 居中 */
      div {
        width: 10.917rem;
        height: 13.833rem;
        margin-right: 0.5rem;
        overflow: hidden;
        border-right: 0.042rem solid #dee2ff;
        img {
          width: 10.917rem;
          height: 13.833rem;
        }
      }
    }
  }
  .iconleft {
    .iconleft1 {
      width: 1.042rem;
      height: 2.333rem;
      // background-color: red;
      // margin-left: 344px;
      // margin-top: -343px;
      position: absolute;
      left: 14.333rem;
      top: 14.292rem;
    }
  }
  .iconright {
    .iconright1 {
      width: 1.042rem;
      height: 2.333rem;
      // background-color: green;
      // margin-left: 344px;
      // margin-top: -343px;
      position: absolute;
      // right: 15.5rem;
      top: 14.292rem;
      left: 62.3rem;
    }
  }
}
</style>
<style lang="less">
/* 固定 */
.companyHonor .el-carousel__container {
  height: 13.833rem !important;
}
.companyHonor .el-carousel__button {
  width: 2rem !important;
}
</style>
